import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>

            <div className="scrollToTop">
                <span className="arrow">
                    <i className="ri-arrow-up-s-fill fs-20" />
                </span>
            </div>
            <div id="responsive-overlay" />
            <footer className="footer mt-auto py-3 bg-white text-center">
                <div className="container">
                    <span className="text-muted">
                        Copyright © <span id="year" />
                        <a href="javascript:void(0);" className="text-dark fw-semibold">
                            Tailor Management System
                        </a>
                        . Designed  <span className="bi bi-heart-fill text-danger" /> by
                        <Link to="https://onebigbit.com/" target='_blank'>

                            <span className="fw-semibold text-primary text-decoration-underline">
                               &nbsp;  OneBigBit Technology Pvt Ltd &nbsp;
                            </span>
                        </Link>
                        All rights reserved
                    </span>
                </div>
            </footer>
        </>
    )
}

export default Footer
