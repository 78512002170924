import React, { useEffect,useState} from 'react'
import { Row, Col, Card, CardHeader, CardBody,CardTitle, Container } from "reactstrap"
import PageHeader from '../PageHeader';
import { useNavigate } from 'react-router-dom';


const PaymentsDetails = () => {
    const navigate = useNavigate();
    const api_url = process.env.REACT_APP_BASE_URL;
    const [searchTerm, setSearchTerm] = useState('');
    const [Data, setData] = useState([]);
    const fetchdataorder = async () => {
        try {
          const response = await fetch(`${api_url}/fetchdataorder`);
          const data = await response.json();
          setData(data);
        } catch (error) {
          console.log(error.message);
        }
      };
      useEffect(() => {
        fetchdataorder();
      },); 
// Assuming data is an array of objects with the structure you provided
//const totalPaidAmount = Data.reduce((total, order) => total + order.paidamount.reduce((acc, val) => acc + val.paid, 0), 0);

//console.log("Total paid amount for all orders:", totalPaidAmount);

      const filtereddata = Data.filter(a => a.orderId.toLowerCase().includes(searchTerm.toLowerCase())|| 
      a.customername.toLowerCase().includes(searchTerm.toLowerCase()) ||
      a.phone.toLowerCase().includes(searchTerm.toLowerCase()));
  
      const [filterdata,setfilter]=useState([]);
  const handleFilter=(selecteds)=>{
      console.log(selecteds);
      const x = Data.filter(
        (item) => item.Payment_status.toLowerCase() === selecteds.toLowerCase()
    );
      setfilter(x);
  };
  const renderedData = filterdata.length > 0 ? filterdata : filtereddata;

  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * 10;
const endIndex = currentPage * 10;

// Function to the next page
const nextPage = () => {
  setCurrentPage((prevPage) => prevPage + 1);
};

// Function t0 the previous page
const prevPage = () => {
  setCurrentPage((prevPage) => prevPage - 1);
};

    return (
        <>
            <div className="main-content app-content " id='backscreen' >
                <Container fluid={true}>
                    <PageHeader title="Payment Details" />
                    <Row>
                        <Col xl={12}>
                            <Card className=" custom-card">
                                <CardHeader className="justify-content-between">
                                    <CardTitle> All Payment Details
                                    <div className="d-flex flex-wrap gap-2">
                                        <div>
                                            <input
                                                className="form-control form-control-sm"
                                                type="text"
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                placeholder="Search Here"
                                                aria-label=".form-control-sm example"
                                            />
                                        </div>
                                      
                                    </div>

                                    </CardTitle>
                                    <br />
                                  
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive">
                                    <table className="table table-responsive table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>                                                  
                                                    <th scope="col">Order Id</th>
                                                    <th scope="col">Customer Name</th>
                                                    <th scope="col">Phone</th>                                                              
                                                    <th scope="col" style={{ width: '150px' }}>
                                                                <p>
                                                                    Payment Status &emsp;
                                                                    <select
                                                                        name="status"
                                                                      //  defaultValue="Paid"
                                                                        id="status"
                                                                        className='border selectintable'
                                                                        onChange={(e) => handleFilter(e.target.value)}>
                                                                        <option value="Paid">Paid</option>
                                                                        <option value="Unpaid">Unpaid</option>
                                                                        <option value="Partially">Partially</option>
                                                                    </select>
                                                                </p>
                                                            </th>

                                                    <th scope="col">Due Amount</th>
                                                    <th scope='col'>Credit</th>
                                                    {/* <th scope="col"> Paid Amount</th> */}
                                                    <th scope="col">Payment</th>
                                                    
                                                   
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {renderedData.slice(startIndex, endIndex).map((data,index)=>(
                                                    <tr key={data._id}>
                                                        <th>{startIndex+index+1}</th>                                                      
                                                        <td>{data.orderId}</td>
                                                        <td>{data.customername}</td>
                                                        <td>{data.phone}</td>                                                                                                             
                                                        <td>
                                                        <span className={
                                                            data.Payment_status === 'Paid' ? 'badge bg-success-transparent' :
                                                            data.Payment_status === 'Unpaid' ? 'badge bg-danger-transparent' :
                                                            'badge bg-warning'
                                                        }>
                                                            {data.Payment_status}
                                                        </span>

                                                        </td>
                                                        <td><i className='fa fa-inr'></i>{data.Totalamount - data.paidamount.reduce((total, data) => total + data.paid, 0)}</td>
                                                        <td><i className='fa fa-inr'></i>{data.paidamount.reduce((total, data) => total + data.paid, 0)}</td>
                                                       <td>
                                                       <button
                                                className="btn btn-primary-light btn-icon btn-sm"
                                                title="Paid bill"
                                                onClick={() =>{
                                                   // window.open(`/InvoicePrint/${data.orderId}`, '_blank')
                                                      navigate(`/Addpayment/${data.orderId}`)                                                  
                                                }}
                                                >  <i className="ri-bank-card-line" />
                                                                                        
                                                </button> 
                                              </td>   </tr>
                                                ))}                                              
                                            </tbody>
                                        </table> <div className="pagination justify-content-end mb-0 mt-4">
                                                <button
                                                className="btn btn-outline-secondary mr-2"
                                                onClick={prevPage}
                                                disabled={currentPage === 1}
                                                >
                                                Previous
                                                </button>
                                                <button
                                                className="btn btn-outline-secondary ml-2"
                                                onClick={nextPage}
                                                disabled={endIndex >= renderedData.length}
                                                >
                                                Next
                                                </button>
                                            </div>
                                            
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row></Container>


            </div>
        </>
    )
}

export default PaymentsDetails
