import React, { useState,useEffect,useRef } from 'react';
import PageHeader from '../PageHeader'
import { Row, Col, Card, CardHeader, CardBody,CardTitle, Container } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from 'react-select';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from '@chakra-ui/react'
import Swal from 'sweetalert2'

const Mapping = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const refContainer = useRef(null)
  const api_url = process.env.REACT_APP_BASE_URL;
  const [parts, selectedParts] = useState();
  const [article_name, setArticle_name] = useState([]);
  const[selectedArticle_name,setselectedArticle_name]=useState();
  const [selectedData, setSelectedData] = useState([]);
  const [elements, setElements] = useState([]);
  const [elementdata, setElementdatas] = useState([]);
  const [maparticles, setArticlesmap] = useState([]);
 // const [elementData, setElementData] = useState([]);
  const [loading, setLoading] = useState(true);

const fetchmap = async () => {
    try {
      const response = await fetch(`${api_url}/elementmapping`);
      if (!response.ok) {
        throw new Error('Failed to fetch articles');
      }
      const data = await response.json();
      setArticlesmap(data);
   
    } catch (error) {
      console.log(error.message);   
    }
  };
const fetchArticlename = async () => {
        try {  
          const response = await fetch( `${api_url}/articlename`); 
          const data = await response.json();
          setArticle_name(data);   
        } catch (error) {
          console.log(error.message);   
        }
      };
   
const fetchData = async (selectedArticle_name,parts,selectedpartname) => {
        try {
          if (!selectedArticle_name || !parts|| !selectedpartname) { return;
          }
          const response = await fetch( `${api_url}/elementmapping/${selectedArticle_name}/${parts}/${selectedpartname} `,);    
          if (response.ok) {
          
            setLoading(false);
          } else {
            console.error('Failed to fetch element data', response.status);
            setLoading(true);
          }
        } catch (error) {
          console.error('Error fetching element data:', error.message);
        }
      };
const[articlepartname,setarticlepartname]=useState([])
const fetchpartname = async (selectedArticle_name,parts) => {
  try {
    if (!selectedArticle_name || !parts) { return;
    }
    const response = await fetch( `${api_url}/article/${selectedArticle_name}/${parts} `,);    
    if (response.ok) {
      const data = await response.json();
      setarticlepartname(data);
    } else {
      console.error('Failed to fetch element data', response.status);
      setarticlepartname([]);
      if(parts==="Lower")
{
  selectedParts("Upper");
}     else{
  selectedParts("Lower");
}
    }
  } catch (error) {
    console.error('Error fetching element data:', error.message);
  }
};
useEffect(() => {
                  fetchmap();
                  fetchArticlename();
                },[]);
  const[selectedpartname,setselectedpartname]=useState();
useEffect(() => { 
              if(selectedArticle_name && parts){        
                  fetchpartname(selectedArticle_name,parts); 
                }     
               }, [selectedArticle_name,parts]);
useEffect(() => {
                setselectedpartname(articlepartname[0]);  
                }, [articlepartname]);
useEffect(()=>{
              if(selectedArticle_name && parts && selectedpartname){
                fetchData(selectedArticle_name,parts,selectedpartname);  
              }  
              },[selectedArticle_name,parts,selectedpartname]);

const handlearticlechange =(e)=>{  
  setselectedArticle_name(e.target.value);
  console.log('Selected Article name:', e.target.value);
  setElements([]) 
 // refContainer.current.reset()
  setselectedpartname("");
 // setselectedpartname("");
  //setarticlepartname("");
 
}

const handlepartchange=(e)=>{selectedParts(e.target.value);console.log('Selected Part Type:', e.target.value);} 
const handlepartnamechange=(e)=>{ setselectedpartname(e.target.value);console.log('Selected Part Name:', e.target.value);}
const handleElements = (selectedOptions) => {
        setElements(selectedOptions);
        const selectedDataArray = selectedOptions.map(selectedElement =>
          elementdata.find(item => item === selectedElement.value)
        );
        setSelectedData(selectedDataArray);
      }
      const isSubmitDisabled = elements.length === 0;
     
const onvalidsubmit = async(e) => {
            e.preventDefault();
            if(!loading){
              Swal.fire({
                icon: "error",
                title: "Exists",
                text: "Data already exists!",
              });
            }
            
            else{
              try {
                let payload = {
                    articlename: selectedArticle_name,
                    type: parts,
                    partname:selectedpartname,
                    element: selectedData,
                }
                console.log(payload, "PL");

                const response = await fetch( `${api_url}/elementmapping`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                  });
                  console.log(response.status, "PL");

                  if (response.ok) {
                    Swal.fire({
                      title: "Success!",
                      text: "Element Mapping  succeessfully!",
                      icon: "success"
                    });
                      const updatedData = await response.json();
                      console.log('Response Body:', updatedData);           
                      fetchmap();
                      fetchData(selectedArticle_name,parts,selectedpartname);  
                      setArticlesmap((presdata)=> presdata.map((data) => 
                      data._id === updatedData._id ? {...data,...updatedData} :data
                      ));
                      setLoading(true);
                  } else {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                    });
                      console.error('Failed to submit form');
                  }
              } catch (error) {
                  console.error('Error submitting form:', error);
              }  
            } 
 }

const deletedata= async(id)=>{
          try {
            const result = await Swal.fire({
              title: "Are you sure?",
              text: "You won't be able to revert this!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, delete it!"
            });  
            if (result.isConfirmed) {
            const response = await fetch( `${api_url}/elementmapping/${id}`, {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
              },
            });
            console.log(response.status, "PLdelete",response.ok);
            if (response.ok) {
            const updatedData = maparticles.filter((item) => item._id !== id);
            setArticlesmap(updatedData);
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success"
            });
            } else {
              Swal.fire({
                title: "Error!",
                text: "Failed to delete the element.",
                icon: "error"
              });
              console.error('Failed to delete article from server');
            }
          }
          } catch (error) {
            Swal.fire({
              title: "Error!",
              text: "An unexpected error occurred.",
              icon: "error"
            });
            console.error('Error deleting article:', error.message);
          }
        };
  const[Articledata,setArticleData]=useState([]);
  const articlefetchbyid= async(id)=>{
    try {
      console.log("id",id);
      const response = await fetch(`http://localhost:5000/elementmappings/${id}`);
      if (response.ok) {
        const data = await response.json();
        setArticleData(data);      
      } else {
        console.error(`Failed to fetch customer data. Status: ${response.status}`);
      }
         
    } catch (error) {
      console.error('Error fetching customer data:', error.message);
    }
  }
const [selectedOptionsbyid, setSelectedOptionsbyid] = useState([]);
useEffect(() => {
  const initialArray = Articledata.element || [];
  setSelectedOptionsbyid(
    initialArray.map((item) => ({
      label: item,
      value: item,
    }))
  );
}, [Articledata.element]);
const handleSelectChange = (selectedValues) => {
  if (selectedValues.length > 0) {
    setSelectedOptionsbyid(selectedValues);
  } 
  console.log(selectedOptionsbyid);
};
const selectedValuesArray = selectedOptionsbyid.map((option) => option.value);
const onvalidupdatesubmit = async(id) => {
          let payload={
            articlename: Articledata.articlename,
            type: Articledata.type,
            partname:Articledata.partname,           
            element: selectedValuesArray,
          }
          const result = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, update it!"
          });
          if (result.isConfirmed) {
          const response = await fetch( `${api_url}/updatelementmapping/${id}`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(payload),
            });    
            if (response.ok) {       
              const updatedData = await response.json();
              setArticlesmap((pre)=> pre.map((items) => 
              items._id === updatedData._id ? {...items,...updatedData} :items
              ));
            } else {
              Swal.fire({
                title: "Error!",
                text: "Failed to update the element.",
                icon: "error"
              });
              console.error(`Failed to update data. Status: ${response.status}`);
            }
          }}
  useEffect(() => {
    const fetchTypeData = async () => {
        try {
          if (parts === 'Upper' || Articledata.type === 'Upper' ){
            const response = await fetch( `${api_url}/element/Upper`);     
              const data = await response.json();
                   setElementdatas(data);    
                   setElements([]) 
          }
          else if(parts === 'Lower' || Articledata.type === 'Lower'){
            const response = await fetch( `${api_url}/element/Lower`); 
                 const data = await response.json();
                   setElementdatas(data); 
                   setElements([])
          }         
        } catch (error) {
          console.log(error.message);  
      }
    };
      fetchTypeData();
  }, [parts,Articledata.type,api_url]); 
  return (
    <>
      <div className="main-content app-content" >
        <Container fluid={true}>
          <PageHeader title="Element Mapping" />
          <Row>
            <Col xl={1}></Col>
            <Col xl={10}>
              <Card className=" custom-card">
                <CardHeader className="justify-content-between">
                  <CardTitle> Element Mapping</CardTitle>
                </CardHeader>
                <CardBody>
                  <AvForm onValidSubmit={onvalidsubmit}  ref={refContainer}>
                    <Row className='element_mapping'>
                      <Col md={2}>
                        <AvField type="select" value={article_name} onChange={handlearticlechange} 
                        name="article_name" label="Article Name">
                          <option >Select</option>
                          {article_name.map((type, index) => ( 
                              <option key={index} value={type}>{type}</option>
                              ))}
                          
                        </AvField>
                      </Col>
                    
                      <Col md={2}>
                        <AvField type="select" name="parts" value={parts} onChange={handlepartchange} label="Type">
                          <option selected>Select</option>
                          <option value='Upper'>Upper</option>
                          <option value='Lower'>Lower</option>
                        </AvField>
                      </Col>
                      <Col ms={2}>    
                        <AvField type="select" name="article_part_name"   
                        value={articlepartname}  onChange={handlepartnamechange}  label="Part Name">                        
                            {/* <option selected>Select</option> */}
                          {articlepartname.map((type, index) => ( 
                              <option key={index} value={type}>{type}</option>
                              ))}
                          </AvField>
                      </Col>
                      <Col md={4}>
                        <label htmlFor='selement' className='mb-2'>Select Element</label>
                   
                        {
                          parts === 'Upper' ? (
                            <Select
                              isMulti
                              name="elements" value={elements} 
                              onChange={handleElements}                          
                              options={elementdata.map(item => ({
                                label: item,
                                value: item,
                              }))}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              id="selement"
                              validate={{ required: { value: true } }} 
                            />
                          ) : parts === 'Lower' ? (
                            <Select
                              isMulti
                              name="elements" value={elements} onChange={handleElements}
                             
                              options={elementdata.map(item => ({
                                label: item,
                                value: item,
                              }))}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              id="selement"
                              validate={{ required: { value: true } }} 
                            />
                          ) : (
                            <Select
                              isMulti
                              name="elements" value={elements}
                              onChange={handleElements}
                               options={elementdata.map(item => ({
                                label: item,
                                value: item,
                              }))}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              id="selement"
                              validate={{ required: { value: true } }} 
                            />
                          )
                            }
                      </Col>
                      <Col md={2} className='mb-5'>
                        <div className="btn-list mt-4  pt-1">
                          <button type="submit" className="btn btn-success btn-wave"
                           disabled={isSubmitDisabled}
                          >Add <i className='fa fa-plus-circle'></i></button>
                        </div>
                      </Col>
                     </Row>
                  </AvForm>
                </CardBody>
                <div className="card-footer d-none border-top-0">
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={1}></Col>
            <Col xl={10}>
              <Card className=" custom-card">
                <CardHeader className="justify-content-between">
                  <CardTitle> Mapped Article</CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive">
                    <table className="table text-nowrap table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Article</th>
                          <th scope="col">Type</th>
                          <th scope="col">Part Name</th>
                          <th scope="col">Elements</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                {maparticles.map((a,index) => ( 
                      <tr key={a._id}>             
                         <th>{index+1}</th>
                          <th scope="row">
                          {a.articlename}
                          </th>
                          <td>{a.type}</td>
                          <td>{a.partname}</td>
                          <td>{a.element.join(", ")}</td>
                          <td>  
                            <div className="hstack gap-2 flex-wrap">
                              <a onClick={() => { onOpen(); articlefetchbyid(a._id); }}
                              
                              class="btn btn-icon btn-sm btn-info-transparent rounded-pill"><i
                                                                        class="ri-edit-line"></i></a>
                              <a className="text-danger fs-14 lh-1" onClick={() => deletedata(a._id)}  class="btn btn-icon btn-sm btn-danger-transparent rounded-pill"><i
                                                                            class="ri-delete-bin-line"></i></a>
                            </div>
                          </td>
                          
                        </tr>))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Element Mapping</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          {Articledata? 
          (  
          <AvForm >
              <Row className='element_mapping'>
                <Col md={12}>
                  <AvField 
                   type="text"
                   value={Articledata.articlename}  
                   defaultValue={Articledata.articlename}
                   name="articlename"
                   label="Article Name" 
                   readOnly={true}/>                
                </Col>
                <Col md={12}>
                  <AvField  
                  type="text"
                  name="type" 
                  value={Articledata.type}  
                  readOnly={true}
                  label="Type(Lower or Upper)"/>                                                  
                </Col>
                <Col md={12}>
                  <AvField  
                  type="text"
                   name="partname" 
                   value={Articledata.partname} 
                    label="Article Part Name"
                    readOnly={true}/>   
                </Col>             
                <Col md={12}>
                  <label htmlFor='selement' className='mb-2'>Select Element</label>
                  <Select
                              isMulti
                              name="element" 
                              value={selectedOptionsbyid} 
                              onChange={handleSelectChange}         
                              options={elementdata.map(item => ({
                                label: item,
                                value: item,
                              }))}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              id="selement"
                            />                  
                </Col>
                <Col md={12} className='mb-2'>
                  <div className="btn-list mt-4 text-end pt-1">
                    <button type="submit" onClick={()=>{onClose(); onvalidupdatesubmit(Articledata._id);}} className="btn btn-success btn-wave">Update <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm> ):( <p>Loading Article data not found ...</p>)}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Mapping
