import React ,{useRef} from 'react'
import Aside from '../Components/Aside'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import PageHeader from '../Components/PageHeader'
import { Row, Col, Card, CardHeader, CardBody, FormGroup, Button, CardTitle, Container } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Swal from 'sweetalert2'
const ChangePassword = () => {
    const formRef = useRef();

    const handlesubmit = async (e, v) => {
        const admin=   localStorage.getItem('username');
        e.preventDefault();       
        try {
     let payload = {
        username:admin,
        oldpwd: v.oldPassword,
        newpwd: v.newPassword
        };
          const token = localStorage.getItem("authToken");
          console.log(payload,token,"change");
          const api_url = process.env.REACT_APP_BASE_URL;
          const response = await fetch(`${api_url}/adminuser/changepassword`, {
            method: 'POST', 
            headers: {
              'Content-Type': 'application/json', 
              Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(payload)
          });

          const result = await response.json();
          if (result.status === 1) {
              Swal.fire({
                  title: result.message,
                  icon: 'success',
              });
          } else {
              Swal.fire({
                  title: 'Invalid old password!',
                  text: result.message,
                  icon: 'error',
              });
          }
          formRef.current.reset()
        } catch (error) {
          console.error('Password change failed', error);
        }
      };
      
    return (
        <>
            <Header />
            <Aside />
            <div className="page">
                <div className="main-content app-content">
                    <Container fluid={true}>
                        <PageHeader title="Change Password" />
                        <Row>
                            <Col xl={3}></Col>
                            <Col xl={6}>
                                <Card className=" custom-card">
                                    <CardHeader className="justify-content-between">
                                        <CardTitle>Change Password {localStorage.getItem("name")}</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                    <AvForm onValidSubmit={handlesubmit} ref={formRef}>
                                            <Row className='accordion_css'>
                                                <Col md={12} >
                                                    <AvField
                                                        name="oldPassword"
                                                        type="text"
                                                        label="Old Password*"
                                                        className="form-control"
                                                        placeholder="Enter Old Password"
                                                    />
                                                </Col>
                                                <Col md={12}>
                                                    <AvField
                                                        name="newPassword"
                                                        type="text"
                                                        label="New Password*"
                                                        className="form-control"
                                                        placeholder="Enter New Password"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12}>
                                                    <FormGroup className="mb-0 text-center mt-5">
                                                        <div>
                                                            <Button
                                                                type="submit"
                                                                color="primary"
                                                                className="mr-1"
                                                            >
                                                                Submit
                                                            </Button>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </CardBody>
                                    <div className="card-footer d-none border-top-0">
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />

        </>
    )
}

export default ChangePassword
