import React from 'react';

const Avatar = ({ name }) => {
  const getInitials = (name) => {
    return name.charAt(0).toUpperCase();
  };

  return (
    <div className="avatar me-sm-2 me-0">
      {name ? (
        <div  className="avatar-circle rounded-circle bg-primary d-flex justify-content-center align-items-center" >
          <span  className="avatar avatar-xs rounded-circle bg-primary">{getInitials(name)}</span>
        </div>
      ) : (
        <img
          src="/assets/images/userjpg"
          alt="img"
          width={32}
          height={32}
          className="rounded-circle"
        />
      )}
    </div>
  );
};

export default Avatar;
