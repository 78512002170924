import React from 'react'
import Aside from '../Components/Aside'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Modal from '../Components/Modal'
import HomeContent from '../Components/PageComponents/HomeContent'

const Home = () => {
  return (
    <>
      <Header />
      <Aside />
      <div className="page">
        <HomeContent />
        <Modal />
      </div>
      <Footer />
    </>
  )
}

export default Home
