import React from 'react'

const Modal = () => {
    return (
        <>
            <div
                className="modal fade"
                id="searchModal"
                tabIndex={-1}
                aria-labelledby="searchModal"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="input-group">
                                <a
                                    href="javascript:void(0);"
                                    className="input-group-text"
                                    id="Search-Grid"
                                >
                                    <i className="fe fe-search header-link-icon fs-18" />
                                </a>
                                <input
                                    type="search"
                                    className="form-control border-0 px-2"
                                    placeholder="Search"
                                    aria-label="Username"
                                />
                                <a
                                    href="javascript:void(0);"
                                    className="input-group-text"
                                    id="voice-search"
                                >
                                    <i className="fe fe-mic header-link-icon" />
                                </a>
                                <a
                                    href="javascript:void(0);"
                                    className="btn btn-light btn-icon"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >

                                    <i className="fe fe-more-vertical" />
                                </a>

                            </div>
                            <div className="mt-4">
                                <p className="font-weight-semibold text-muted mb-2">
                                    Are You Looking For...
                                </p>
                            </div>
                            
                        </div>
                        <div className="modal-footer">
                            <div className="btn-group ms-auto">
                                <button className="btn btn-sm btn-primary-light">Search</button>
                                {/* <button className="btn btn-sm btn-primary">Clear Recents</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Modal
