import React from 'react'
import Aside from '../Components/Aside'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

import { Row, Col, Card, CardHeader, CardBody, CardTitle, Container } from "reactstrap"
import PageHeader from '../Components/PageHeader'
import {
    Modal,

} from '@chakra-ui/react'
// import Modal from '../Components/Modal'

const Contact = () => {
 

    return (
        <>
            <Header />
            <Aside />
            <div className="page">
                <div className="main-content app-content" >
                    <Container fluid={true}>
                        <PageHeader title="Contacts" />
                        <Row>
                            <Col xl={12}>
                                <Card className=" custom-card">
                                    <CardHeader className="justify-content-between">
                                        <CardTitle>
                                            Contacts
                                        </CardTitle>
                                        <br />
                                        {/* <div className="d-flex flex-wrap gap-2">
                                            <div>
                                                <input
                                                    className="form-control form-control-sm"
                                                    type="text"
                                                    placeholder="Search Here"
                                                    aria-label=".form-control-sm example"
                                                />
                                            </div>
                                        </div> */}
                                    </CardHeader>
                                    <CardBody>
                                        <div className="table-responsive">
                                            <table className="table text-nowrap table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col"> Name</th>
                                                        <th scope="col">Phone</th>                                                       
                                                        <th scope="col"> Address</th>
                                                        {/* <th scope="col">Action </th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>1</th>
                                                        <th scope="row">  Yunus </th>
                                                        <td>+91 9415918850</td>
                                                        
                                                        <td>Rikabganj Road,Chowk,Faizabad</td>
                                                        {/* <td>
                                                            <div className="hstack gap-2 flex-wrap">
                                                                <a href="javascript:void(0);" onClick={onOpen} className="text-info fs-14 lh-1">
                                                                    <i className="ri-edit-line" />
                                                                </a>
                                                                <a href="javascript:void(0);" className="text-danger fs-14 lh-1">
                                                                    <i className="ri-delete-bin-5-line" />
                                                                </a>
                                                            </div>
                                                        </td> */}
                                                    </tr>
                                                    <tr>
                                                        <th>2</th>
                                                        <th scope="row">  Yunus </th>
                                                        <td>+91 9935742210</td>
                                                        
                                                        <td>Rikabganj Road,Chowk,Faizabad</td>
                                                        {/* <td>
                                                            <div className="hstack gap-2 flex-wrap">
                                                                <a href="javascript:void(0);" onClick={onOpen} className="text-info fs-14 lh-1">
                                                                    <i className="ri-edit-line" />
                                                                </a>
                                                                <a href="javascript:void(0);" className="text-danger fs-14 lh-1">
                                                                    <i className="ri-delete-bin-5-line" />
                                                                </a>
                                                            </div>
                                                        </td> */}
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row></Container>
                </div>
                {/* <Modal isOpen={isOpen} size="lg" onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Update Contact Details</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <AvForm>
                                <Row className='accordion_css'>
                                    <Col md={3}>
                                        <p> Name:</p>
                                    </Col>
                                    <Col md={9}>
                                        <AvField
                                            name="name"
                                            type="text"
                                            value="John"
                                            validate={{ required: { value: true } }}
                                            className="form-control"
                                            placeholder="Enter  Name"
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <p>Phone:</p>
                                    </Col>
                                    <Col md={9}>
                                        <AvField
                                            name="phone"
                                            type="number"
                                            value="999999999"
                                            validate={{ required: { value: true } }}
                                            className="form-control"
                                            placeholder="Enter Phone"
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <p>Email:</p>
                                    </Col>
                                    <Col md={9}>
                                        <AvField
                                            name="email"
                                            type="email"
                                            value="abc@gmail.com"
                                            validate={{ required: { value: true } }}
                                            className="form-control"
                                            placeholder="Enter Email"
                                        />
                                    </Col>
                                    
                                    <Col md={3}>
                                        <p>Address:</p>
                                    </Col>
                                    <Col md={9}>
                                        <AvField
                                            className="form-control"
                                            type="textarea"
                                            value="Aliganj, Lucknow - 226024"
                                            placeholder="Enter Address Line 1"
                                            name="address"
                                            validate={{ required: { value: true } }}
                                            errorMessage="Address cannot be empty"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <FormGroup className="mb-0 text-center mt-3 ">
                                            <Button variant='ghost' onClick={onClose}>Update <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                            </Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </AvForm>
                        </ModalBody>
                    </ModalContent>
                </Modal> */}
                <Modal />
            </div>
            <Footer />


        </>
    )
}

export default Contact
