import React, { useEffect,useState,useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  Container,
} from "reactstrap";
import Select from "react-select";
import PageHeader from "../PageHeader";
import {
  AvForm,
  AvField,
  AvCheckbox
} from "availity-reactstrap-validation";
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import "flatpickr/dist/themes/material_green.css";

import Flatpickr from "react-flatpickr";
import { Component } from "react";

const Addorder = () => {
  const navigate = useNavigate();
  const api_url = process.env.REACT_APP_BASE_URL;
  const refContainer = useRef(null)
  const [selectedDate, setSelectedDate] = useState(null);
  const [customerName,setcustomerName]=useState([]);
  const [customerarticledata,setcustomerarticledata]=useState();
  const[phone,setphone]=useState();
  const[address,setAddress]=useState();
  //const[status,setstatus]=useState("Unpaid");
  const[selectedCustomername,setselectedCustomername]=useState('');
  const onhandleaddresschange= (e)=>{ setAddress(e.target.value); console.log(e.target.value);}
  const fetchcustomername = async () => {
    try {
      const response = await fetch(`${api_url}/customername`);       
      const data = await response.json();
      setcustomerName(data);     
    } catch (error) {
      console.log(error.message);       
    }};
    useEffect(() => {
      fetchcustomername();          
    });
const fetchaddressphone = async (selectedCustomername) => {
    try {
      const response = await fetch(`${api_url}/customeradddress/${selectedCustomername}`);       
      const data = await response.json();
      setphone(data.phone)
      setAddress(data.address1)
      console.log(data.phone);   
    } catch (error) {
      console.log(error.message);       
    }};
    const fetchdata = async (selectedCustomername) => {
      try {
        const response = await fetch(`${api_url}/searchcustomerdata/${selectedCustomername}`);       
        const data = await response.json();
        setcustomerarticledata(data);   
      } catch (error) {
        console.log(error.message);       
      }};
      
      useEffect(() => { 
        if(selectedCustomername) {
          fetchdata(selectedCustomername); 
          fetchaddressphone(selectedCustomername); 
        }
      }, [selectedCustomername]);
       
const [selectedValues, setSelectedValues] = useState([]);
 const [v, setV] = useState({}); 

 const handleArticleChange = (event,index) => {

   const fieldName = event.target.name; 
   const value = event.target.value;

   setV((prevV) => ({
     ...prevV,
     [fieldName]: value,
   }));
 };
 const calculateTotalPrice = (index) => {
   const unit = parseInt(v[`unit${index}`], 10) ||0;
   const charge = parseFloat(v[`charge${index}`]) ||0;
   return (unit * charge).toFixed(2);
 };

 let totalAmount = 0;
 const [discount,setdiscount]=useState('0');
 const [advanced,setadvanced]=useState("0");

{selectedValues.forEach((selectedArticlename, index) => {
  const unit = parseInt(v[`unit${index}`], 10) || 0;
  const charge = parseFloat(v[`charge${index}`]) ||0;
  totalAmount += unit * charge;

})};
let remainingAmount=(totalAmount*((100-discount)/100)-advanced).toFixed(2);
let Payment_status='Unpaid';
if (remainingAmount <= 0.00){
   Payment_status='Paid';

}

const [paidamount,setPaidamount]=useState('0');
const [Delivery_status,setDelivery_status]=useState(false);
const submitForm = async (e, v) => {
  e.preventDefault();
  try {
    let payload = {
      orderId: "order" + Date.now(),
      customername: selectedCustomername,
      phone: phone,
      address: address,
      Delivery_Date:selectedDate,
      Delivery_status:Delivery_status,
      item: [],
      Discount: discount,
      Advanced: advanced,
      amount: totalAmount,
      Totalamount: remainingAmount,
      Payment_status:Payment_status,
      paidamount:[],
    };
     for (const [index, selectedArticlename] of selectedValues.entries()) {
      const newItem = {
                  articlename: selectedArticlename.value,
                  Unit: v[`unit${index}`],
                  price: v[`charge${index}`],
                  totalofarticle: v[`subtotal${index}`],
                };
                payload.item.push(newItem);
    }
   

console.log(payload,"pl");
      const response = await fetch(`${api_url}/orderadd`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      // const responseData = await response.json();
      // console.log('Response Body:', responseData);
      // console.log(response.statusText, 'successfully');
      if (response.ok) {
        // Swal.fire({
        //   title: "Success!",
        //   text: "Order add succeessfully!",
        //   icon: "success",
        // });
          const responseData = await response.json();
          console.log('Response Body:', responseData);
          console.log(response.statusText, 'successfully');
          window.open(`/InvoicePrint/${payload.orderId}`, '_blank')
          navigate("/Orders");
        //navigate(`/InvoicePrint/${payload.orderId}`)
        refContainer.current.reset()
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.error("Failed to submit form");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };


  return (
    <>
      <div className="main-content app-content">
        <Container fluid={true}>
          <PageHeader title="All  Order" />
          <Row>
            <Col xl={1}></Col>
            <Col xl={10}>
              <Card className=" custom-card">
                <CardHeader className="justify-content-between">
                  <CardTitle> Add Order</CardTitle>
                </CardHeader>
                <CardBody>
                  <AvForm onValidSubmit={submitForm}  ref={refContainer}>
                    <Row className="accordion_css">
                      <Row>                   
                      <Col md={4}>
                      <label><b>Custome Name</b></label>                     
                          <Select
                           type="select"
                           name="customer_name"                                                    
                            value={customerName.find((option) => option.value === selectedCustomername)}
                            onChange={(e) => setselectedCustomername(e.value)}
                            options={customerName.map((id) => ({ value: id, label: id }))}
                            isSearchable={true}
                          />     

                      {/* <AvField type="select" name="customer_name" 
                      value={selectedCustomername}
                       onChange={handlecustomerchange}
                        label="Custome Name" >
                             <option selected>all</option>
                       {customerName.map((type, index)=>(
                          <option key={index} value={type}>{type}</option> ))}     
                      </AvField> */}
                      </Col> 
                      <Col md={4}>
                      <label><b>Phone No.</b></label>  
                      <AvField
                       name="phone"
                       type="text"
                      value={phone}
                       pattern="[0-9]*"
                       maxLength="10"                                             
                     className="form-control"
                     placeholder="Enter Phone"
                     readOnly={true}/>
                      </Col>  
                      <Col md={4}>
                      <label><b>Address</b></label>  
                        <AvField
                          name="address"
                          type="textarea"
                          value={address}
                          onChange={onhandleaddresschange}
                        //  onChange={(e)=>{setAddress(address)}}                         
                          validate={{ required: { value: true } }}
                          className="form-control"
                          placeholder="Enter address"
                         // readOnly={true}                 
                        />
                      </Col> 
                      </Row> 
                      <Row>
                      <Col md={4}>
                      <label><b>Choose Article Name</b></label>
                      <Select
                          name="articlename"
                          isMulti
                          value={selectedValues}
                          options={customerarticledata && Array.isArray(customerarticledata) ? customerarticledata.map(articlename => ({
                            label: articlename,
                            value: articlename,
                          })) : []}
                          onChange={(selectedOptions) => setSelectedValues(selectedOptions)}
                        />                  
                     
                        </Col>
                    <Col md={4}>
                   
                      <label><b>Choose Delivery Date</b></label>
                      <AvField
                          type="date"
                          name="datepicker"                          
                          value={selectedDate} 
                          onChange={(e) => setSelectedDate(e.target.value)} 
                              />
                            
                    </Col>
                      </Row> 
                    
                          <Row>   
                        
                          {customerarticledata ? (
                             <div>
                     
                     <div className="table-responsive">
                                      <table className="table text-nowrap table-bordered">
                                          <thead>
                                              <tr>                                                   
                                                  <th scope="col">Article Name</th>                                                   
                                                  <th scope="col"> Unit</th>
                                                  <th scope="col"> charge (in <i className='fa fa-inr'>)</i></th>
                                                  <th scope="col"> Total (in <i className='fa fa-inr'>)</i></th>
                                                  
                                                
                                              </tr>
                                          </thead>
                                          <tbody>
                                          {selectedValues.map((selectedArticlename, index) => (
                                                <React.Fragment key={index}>
                                                  <tr>
                                                    <td>
                                                      <AvField
                                                        name="articlename"
                                                        type="text"
                                                        value={selectedArticlename.value}
                                                        className="form-control"
                                                        placeholder="Enter"
                                                        readOnly={true}
                                                      />
                                                    </td>
                                                    <td>
                                                              <AvField
                                                                name={`unit${index}`}
                                                                type="number"                                                            
                                                               onChange={(event) => handleArticleChange(event,index)}                                                                
                                                                validate={{ required: { value: true } }}
                                                                className="form-control"
                                                              />
                                                            </td>
                                                            <td>
                                                              <AvField
                                                                name={`charge${index}`}
                                                                type="number"                                                               
                                                                onChange={(event) => handleArticleChange(event,index)}                 
                                                                validate={{ required: { value: true } }}
                                                                className="form-control"
                                                              />
                                                            </td>
                                                            <td>
                                                              <AvField
                                                                name={`subtotal${index}`}
                                                                type="number"
                                                                value={calculateTotalPrice(index)}                                                                
                                                                validate={{ required: { value: true } }}
                                                                className="form-control"
                                                                readOnly={true}
                                                              />
                                                            </td>
                                                  
                                                  </tr>
                                                </React.Fragment>
                                              ))}                                           

                       </tbody>
                      </table>
                    </div>  <Row>
                        <Col md={3}>    
                        <AvField
                            name="Discount"
                            type="number"                 
                            value={discount}
                           onChange={(e)=>setdiscount(e.target.value)}                         
                            label="(Discount in %)"
                            validate={{ required: { value: true } }}
                        //    className="form-control"
                          //  placeholder="Enter in percent"
                          />     
                        </Col>
                        <Col md={3}>
                          <AvField
                            name="amount"
                            type="number"
                            value={advanced}
                            onChange={(e)=>setadvanced(e.target.value)}   
                            label="Advanced Amount"
                           validate={{ required: { value: true } }}
                          //  className="form-control"
                         //   placeholder="Enter Amount"
                          />
                        </Col> 
                        <Col md={3}>
                          <AvField
                            name="amount"
                            type="text"
                            value={totalAmount}
                            label="Total Amount"
                            validate={{ required: { value: true } }}
                            className="form-control"
                            readOnly={true}
    
                          />
                        </Col> 
                        <Col md={3}>
                          <AvField
                            name="amount"
                            type="text"
                           value={remainingAmount}
                            label="Due Amount"
                            validate={{ required: { value: true } }}
                            className="form-control"
                          //  placeholder="Total"
                            readOnly={true}
    
                          />
                        </Col>   </Row></div>                
                              
                    ) : (
  <p></p>
)}
                    
                
                   


                 
                    </Row>                    
                      <Col lg={12}>
                        <FormGroup className="mb-0 text-center mt-5">
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1"
                            >
                              Add Order <i className="ri-printer-line"></i>
                            </Button>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Addorder
//  {/* <Row>
//                         {Object.entries(customerarticledata).map(([articlename, partnames],index) => (
//                             <React.Fragment key={index}>
//                               <Col md={3}>
//                                 <AvField
//                                    name={`articlename_${index}`}
//                                   type="text"
//                                   value={articlename}
//                                   label="Article name"
//                                   onChange={(event) => handleArticleChange(event, articlename)}
//                                //   validate={{ required: { value: true } }}
//                                   className="form-control"
//                                   placeholder="Enter"
//                                   readOnly={true}
//                                 />
//                               </Col>
//                               {partnames.map((data, subIndex) => (
//                                 <React.Fragment key={subIndex}>
//                                 <Row>
                              
//                                 <Col md={3} >
//                                     <AvField
//                                        name={`part_${articlename}_${subIndex}`}
//                                       type="text"
//                                       value={data}
//                                       label="Article Part"
//                                      // onChange={(event) => handleArticleChange(event, articlename)}
//                                       validate={{ required: { value: true } }}
//                                       className="form-control"
//                                       placeholder="Enter"
//                                       readOnly={true}
//                                     />
//                                   </Col>
//                                   <Col md={3}>
//                                   <AvField
//                                     name={`unit_${articlename}_${subIndex}`}
//                                     type="text"
//                                     label="Unit"
//                                     onChange={(event) => handleArticleChange(event, articlename ,index)}
//                                     validate={{ required: { value: true } }}
//                                     className="form-control"
//                                     placeholder="unit  "
//                                   />
//                                 </Col> 
//                                 <Col md={3}>
//                                   <AvField
//                                     name={`price_${articlename}_${subIndex}`}
//                                     type="text"
//                                     label="Price"
//                                     onChange={(event) => handleArticleChange(event, articlename ,index)}
//                                     validate={{ required: { value: true } }}
//                                     className="form-control"
//                                     />
//                         </Col> 
//                         <Col md={3}>
//                                   <AvField
//                                      name={`totalprice_${articlename}_${subIndex}`}
//                                     type="text"
//                                     label="subamount"
//                                     onChange={(event) => handleArticleChange(event, articlename ,index)}
//                                     validate={{ required: { value: true } }}
//                                     className="form-control"
//                                     placeholder="total"/>
//                         </Col> 
//                                 </Row>
//                         {/* <Col md={3}></Col> */}
                                
//                         {/* </React.Fragment>
//                         ))}                            
//                           </React.Fragment>
//                           ))}

                      
                    
                    
//                       </Row>  */}




//                         {/* {Object.entries(updatedData).item.map(([articlename, items], index) => (
//                                                     <React.Fragment key={index}>
//                                                         <tr>
//                                                         <td>
//                                                             <div className="fw-semibold">{articlename}</div>
//                                                         </td>
//                                                         {items.map((data, subIndex) => (
//                                                             <React.Fragment key={subIndex}>
//                                                             <td>
//                                                                 <div className="text-muted">{data.articlepart}</div>
//                                                             </td>
//                                                             <td className="product-quantity-container">{data.Unit}</td>
//                                                             <td> ₹{data.price}</td>
//                                                             <td> ₹{data.totalofarticle}</td>
//                                                             </React.Fragment>
//                                                         ))}
//                                                         </tr>
//                                                     </React.Fragment>
//                                                     ))} */}
//                                                      {/* {Object.entries(customerarticledata).map(([articlename, partnames], index) => (
//                                               <React.Fragment key={index}>
//                                                 <tr> <td>
//                                                       <AvField
//                                                         name="articlename"
//                                                         type="text"
//                                                         value={articlename}
//                                                         className="form-control"
//                                                         placeholder="Enter"
//                                                         readOnly={true}
//                                                       />
//                                                     </td>
//                                                 {partnames.map((data, subIndex) => (
//                                                   <tr key={subIndex}>
                                                   
//                                                     <td>
//                                                       <AvField
//                                                         name="partname"
//                                                         type="text"
//                                                         value={data}
//                                                         validate={{ required: { value: true } }}
//                                                         className="form-control"
//                                                       //  placeholder="Enter"
//                                                         readOnly={true}
//                                                       />
//                                                     </td>
//                                                     <td>
//                                                       <AvField
//                                                         name={`unit_${articlename}_${subIndex}`}
//                                                         type="number"
//                                                        // value={2}
//                                                         onChange={(event) => handleArticleChange(event, articlename, subIndex)}
//                                                         validate={{ required: { value: true } }}
//                                                         className="form-control"
//                                                       //  placeholder={`unit_${articlename}_${subIndex}`}
//                                                       />
//                                                     </td>
//                                                     <td>
//                                                       <AvField
//                                                         name={`price_${articlename}_${subIndex}`}
//                                                         type="number"                                                      
//                                                         onChange={(event) => handleArticleChange(event, articlename, subIndex)}
//                                                         validate={{ required: { value: true } }}
//                                                         className="form-control"
//                                                     //    placeholder={`price_${articlename}_${subIndex}`}
//                                                       />
//                                                     </td>
//                                                     <td>
//                                                       <AvField
//                                                         name={`total_${articlename}_${subIndex}`}
//                                                         type="number"
//                                                         value={calculateTotalPrice(articlename, subIndex)}
//                                                         onChange={(event) => handleArticleChange(event, articlename, subIndex)}
//                                                         validate={{ required: { value: true } }}
//                                                         className="form-control"
//                                                       //  placeholder={`total_${articlename}_${subIndex}`}
//                                                         readOnly={true}

//                                                       />
//                                                     </td>
//                                                   </tr>
//                                                 ))}
//                                             </tr>  </React.Fragment>
//                                             ))} */}