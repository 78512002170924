import React from 'react'
import AllRoutes from './AllRoute/AllRoutes';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

function App() {
  return (
    <>
      <AllRoutes />
    </>
  );
}

export default App;
