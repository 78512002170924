import React from 'react'
import Aside from '../Components/Aside'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Modal from '../Components/Modal'
import Addorder from '../Components/PageComponents/AddOrder'

const AddOrders = () => {
    return (
        <>
            <Header />
            <Aside />
            <div className="page">
                <Addorder />
                <Modal />
            </div>
            <Footer />

        </>
    )
}

export default AddOrders
