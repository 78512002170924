import React ,{useState} from 'react'
import { Link } from 'react-router-dom'
// Link

const Aside = () => {
    const [collapsed, setCollapsed] = useState(false);

    const toggleCollapse = () => {
      setCollapsed(!collapsed);
    };
    return (
        <>
        
            <aside className="app-sidebar sticky" id="sidebar">
                <div className="main-sidebar-header">
                    <Link to="/Dashboard" className="header-logo">
                        <img src="assets/images/logo.png"
                            alt="Yunus Tailors"
                            className="desktop-logo"
                            style={{ width: '50px', height: '50px' }}                             
                        />
                        <img src="assets/images/logo.png"
                         style={{ width: '50px', height: '50px' }} 
                        alt="Yunus Tailors"
                            className="toggle-logo"
                        />
                    </Link>
                </div>
                <div className="main-sidebar" id="sidebar-scroll">
                    <nav className="main-menu-container nav nav-pills flex-column sub-open">
                        <div className="slide-left" id="slide-left">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="#7b8191"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                            >
                                <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
                            </svg>
                        </div>
                        <ul className="main-menu">
                            
                            <li className="slide has-sub">
                                <a href="/Dashboard" className="side-menu__item">
                                    <i className="bx bx-home side-menu__icon" />
                                    <span className="side-menu__label">
                                        Dashboard
                                        {/* <span className="badge bg-danger-transparent ms-2">12</span> */}
                                    </span>
                                    <i className="fe fe-chevron-right side-menu__angle" />
                                </a>
                            </li>
                            <li className="slide has-sub">
                                <Link to="/Articles" className="side-menu__item">
                                    <i class="bx bx-file-blank side-menu__icon"></i>
                                    <span className="side-menu__label">
                                        Article
                                    </span>
                                    <i className="fe fe-chevron-right side-menu__angle" />
                                </Link>
                            </li>
                            <li className="slide has-sub">
                                <Link to="/Element" className="side-menu__item">
                                    <i class="bx bx-box side-menu__icon"></i>
                                    <span className="side-menu__label">
                                        Element
                                    </span>
                                    <i className="fe fe-chevron-right side-menu__angle" />
                                </Link>
                            </li>
                            <li className="slide has-sub">
                                <Link to="/ElementMapping" className="side-menu__item">
                                    <i class="bx bx-table side-menu__icon"></i>
                                    <span className="side-menu__label">
                                        Element Mapping
                                    </span>
                                    <i className="fe fe-chevron-right side-menu__angle" />
                                </Link>
                            </li>
                            <li className="slide has-sub">
                                <Link to="/AddElement" className="side-menu__item">
                                    <i class="bx bx-layer side-menu__icon"></i>
                                    <span className="side-menu__label">
                                        Element Measurement
                                    </span>
                                    <i className="fe fe-chevron-right side-menu__angle" />
                                </Link>
                            </li>

                            <li className="slide has-sub">
                                <Link to="/AllCustomers" className="side-menu__item">
                                    <i class="bx bx-user side-menu__icon"></i>
                                    <span className="side-menu__label">
                                        Customers
                                    </span>
                                    <i className="fe fe-chevron-right side-menu__angle" />
                                </Link>
                            </li>
                            <li className="slide has-sub">
                                <Link to="/Orders" className="side-menu__item">
                                    <i class="bx bx-store side-menu__icon"></i>
                                    <span className="side-menu__label">
                                        Orders
                                    </span>
                                    <i className="fe fe-chevron-right side-menu__angle" />
                                </Link>
                            </li>
                           
                            <li className="slide has-sub">
                                <Link to="/Payments" className="side-menu__item">
                                    <i class="bx bx-money side-menu__icon"></i>
                                    <span className="side-menu__label">
                                        Payments
                                    </span>
                                    <i className="fe fe-chevron-right side-menu__angle" />
                                </Link>
                            </li>
                            {/* <li className="slide has-sub">
                                <Link to="/Reports" className="side-menu__item">
                                    <i class="bx bx-file side-menu__icon"></i>
                                    <span className="side-menu__label">
                                        Reports
                                    </span>
                                    <i className="fe fe-chevron-right side-menu__angle" />
                                </Link>
                            </li> */}
                            {/* <li className="slide has-sub">
                                <Link to="/Contact" className="side-menu__item">
                                    <i class="bx bx-user side-menu__icon"></i>
                                    <span className="side-menu__label">
                                        Contacts
                                    </span>
                                    <i className="fe fe-chevron-right side-menu__angle" />
                                </Link>
                            </li> */}
                        </ul>
                        <div className="slide-right" id="slide-right">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="#7b8191"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                            >
                                <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
                            </svg>
                        </div>
                    </nav>
                </div>
            </aside>

        </>
    )
}

export default Aside
