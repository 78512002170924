import React from 'react'
import Aside from '../Components/Aside'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Modal from '../Components/Modal'
import OrdersPage from '../Components/PageComponents/OrdersPage'

const Orders = () => {
    return (
        <>
            <Header />
            <Aside />
            <div className="page">
                <OrdersPage />
                <Modal />
            </div>
            <Footer />
        </>
    )
}

export default Orders
// import React from 'react';
// import { useParams } from 'react-router-dom';

// const InvoicePrint = () => {
//   const { id } = useParams();

//   // Now 'id' contains the value from the route parameter

//   return (
//     <div>
//       <h1>Invoice Print Page</h1>
//       <p>Invoice ID: {id}</p>
//       {/* Add your logic to fetch and display the invoice data */}
//     </div>
//   );
// };

// export default InvoicePrint