import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Avatar from './Avatar';

const Header = () => {
     const navigate = useNavigate();  
     const token=localStorage.getItem("authToken");  
    useEffect(() => {
            let login = localStorage.getItem('authToken');
                if (!login) {
                navigate('/')
            }        
        });
    const logoutuser = async() => {        
        try {
            const api_url = process.env.REACT_APP_BASE_URL;
            const response = await fetch(`${api_url}/adminuser/logout`, {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`, // Include the auth token
              },
            });        
            if (!response.ok) {              
              console.error(`HTTP error! Status: ${response.status}`);
              return;
            }        
            const results = await response.json();
            console.log(results.status, "Asa", response);        
            if (results.status === 1) {             
              localStorage.removeItem('authToken');            
              navigate('/');
            } else {            
              console.error('Logout failed:', results.message);         
            }
          } catch (error) {
            console.error('Error during logout:', error);
          }
        };
 const admin1=   localStorage.getItem('username');
 
    return (
        <>
            <header className="app-header">
                <div className="main-header-container container-fluid">
                    <div className="header-content-left">
                        <div className="header-element">
                            <div className="horizontal-logo">
                                {/* <Link to="/Dashboard" className="header-logo">
                                    <img
                                        src="assets/images/logo.png"
                                        alt="logo"
                                        className="desktop-logo"
                                    />
                                    <img
                                        src="assets/images/logo.png"
                                        alt="logo"
                                        className="toggle-logo"
                                    />
                                    <img
                                        src="assets/images/logo.png"
                                        alt="logo"
                                        className="desktop-dark"
                                    />
                                    <img
                                        src="assets/images/logo.png"
                                        alt="logo"
                                        className="toggle-dark"
                                    />
                                </Link> */}
                            </div>
                        </div>
                        <div className="header-element">
                       
                            {/* <a
                                aria-label="Hide Sidebar"
                                className="sidemenu-toggle header-link animated-arrow hor-toggle horizontal-navtoggle"
                                data-bs-toggle="sidebar"
                                href="javascript:void(0);"
                            >
                                <span />
                            </a> */}
                        </div>
                    </div>
                    <div className="header-content-right">
                    {/* <h1 className="logoheading">Yunus Tailors</h1> */}
                        {/* <div className="header-element header-search">
                            <a
                                href="javascript:void(0);"
                                className="header-link"
                                data-bs-toggle="modal"
                                data-bs-target="#searchModal"
                            >

                                <i className="bx bx-search-alt-2 header-link-icon" />
                            </a>
                        </div> */}
                        <div className="header-element country-selector">
                            {/* <a
                                href="javascript:void(0);"
                                className="header-link dropdown-toggle"
                                data-bs-auto-close="outside"
                                data-bs-toggle="dropdown"
                            >

                                <img
                                    src="../assets/images/flags/us_flag.jpg"
                                    alt="img"
                                    className="rounded-circle"
                                />
                            </a>
                            <ul
                                className="main-header-dropdown dropdown-menu dropdown-menu-end"
                                data-popper-placement="none"
                            >
                                <li>
                                    <a
                                        className="dropdown-item d-flex align-items-center"
                                        href="javascript:void(0);"
                                    >
                                        <span className="avatar avatar-xs lh-1 me-2">
                                            <img src="../assets/images/flags/us_flag.jpg" alt="img" />
                                        </span>
                                        English
                                    </a>
                                </li>
                            </ul> */}
                        </div>
                        {/* <div className="header-element header-theme-mode">
                            <a href="javascript:void(0);" className="header-link layout-setting">
                                <span className="light-layout">
                                    <i className="bx bx-moon header-link-icon" />
                                </span>
                                <span className="dark-layout">
                                    <i className="bx bx-sun header-link-icon" />
                                </span>
                            </a>
                        </div> */}

                        {/* <div className="header-element notifications-dropdown">
                            <a
                                href="javascript:void(0);"
                                className="header-link dropdown-toggle"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="outside"
                                id="messageDropdown"
                                aria-expanded="false"
                            >
                                <i className="bx bx-bell header-link-icon" />
                                <span
                                    className="badge bg-secondary rounded-pill header-icon-badge pulse pulse-secondary"
                                    id="notification-icon-badge"
                                >
                                    4
                                </span>
                            </a>
                            <div
                                className="main-header-dropdown dropdown-menu dropdown-menu-end"
                                data-popper-placement="none"
                            >
                                <div className="p-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <p className="mb-0 fs-17 fw-semibold">Notifications</p>
                                        <span
                                            className="badge bg-secondary-transparent"
                                            id="notifiation-data"
                                        >
                                            4 Unread
                                        </span>
                                    </div>
                                </div>
                                <div className="dropdown-divider" />
                                <ul className="list-unstyled mb-0" id="header-notification-scroll">
                                    <li className="dropdown-item">
                                        <div className="d-flex align-items-start">
                                            <div className="pe-2">
                                                <span className="avatar avatar-md bg-primary-transparent avatar-rounded">
                                                    <i className="ti ti-gift fs-18" />
                                                </span>
                                            </div>
                                            <div className="flex-grow-1 d-flex align-items-center justify-content-between">
                                                <div>
                                                    <p className="mb-0 fw-semibold">
                                                        <a href="#">
                                                            Notification one
                                                        </a>
                                                    </p>
                                                    <span className="text-muted fw-normal fs-12 header-notification-text">
                                                        Order No: 123456 Has Shipped To Your Delivery Address
                                                    </span>
                                                </div>
                                                <div>
                                                    <a
                                                        href="javascript:void(0);"
                                                        className="min-w-fit-content text-muted me-1 dropdown-item-close1"
                                                    >
                                                        <i className="ti ti-x fs-16" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="dropdown-item">
                                        <div className="d-flex align-items-start">
                                            <div className="pe-2">
                                                <span className="avatar avatar-md bg-secondary-transparent avatar-rounded">
                                                    <i className="ti ti-user fs-18" />
                                                </span>
                                            </div>
                                            <div className="flex-grow-1 d-flex align-items-center justify-content-between">
                                                <div>
                                                    <p className="mb-0 fw-semibold">
                                                        <a href="#">Notification two</a>
                                                    </p>
                                                    <span className="text-muted fw-normal fs-12 header-notification-text">
                                                        Discount Available
                                                    </span>
                                                </div>
                                                <div>
                                                    <a
                                                        href="javascript:void(0);"
                                                        className="min-w-fit-content text-muted me-1 dropdown-item-close1"
                                                    >
                                                        <i className="ti ti-x fs-16" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="dropdown-item">
                                        <div className="d-flex align-items-start">
                                            <div className="pe-2">
                                                <span className="avatar avatar-md bg-pink-transparent avatar-rounded">
                                                    <i className="ti ti-user fs-18" />
                                                </span>
                                            </div>
                                            <div className="flex-grow-1 d-flex align-items-center justify-content-between">
                                                <div>
                                                    <p className="mb-0 fw-semibold">
                                                        <a href="#">Notification three</a>
                                                    </p>
                                                    <span className="text-muted fw-normal fs-12 header-notification-text">
                                                        Lorem ipsum dolor sit.
                                                    </span>
                                                </div>
                                                <div>
                                                    <a
                                                        href="javascript:void(0);"
                                                        className="min-w-fit-content text-muted me-1 dropdown-item-close1"
                                                    >
                                                        <i className="ti ti-x fs-16" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="dropdown-item">
                                        <div className="d-flex align-items-start">
                                            <div className="pe-2">
                                                <span className="avatar avatar-md bg-danger-transparent avatar-rounded">
                                                    <i className="ti ti-user fs-18" />
                                                </span>
                                            </div>
                                            <div className="flex-grow-1 d-flex align-items-center justify-content-between">
                                                <div>
                                                    <p className="mb-0 fw-semibold">
                                                        <a href="#">
                                                            Notification four
                                                            <span className="text-warning">ID: #1116773</span>
                                                        </a>
                                                    </p>
                                                    <span className="text-muted fw-normal fs-12 header-notification-text">
                                                        Order Placed Successfully
                                                    </span>
                                                </div>
                                                <div>
                                                    <a
                                                        href="javascript:void(0);"
                                                        className="min-w-fit-content text-muted me-1 dropdown-item-close1"
                                                    >
                                                        <i className="ti ti-x fs-16" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div> */}

                        <div className="header-element header-fullscreen">
                        <img src="assets/images/logo.png"
                         style={{ width: '50px', height: '50px' }} 
                        alt="Yunus Tailor"
                            className="toggle-logo"
                        />
                            {/* <a onClick="openFullscreen();" href="#" className="header-link">

                                <i className="bx bx-fullscreen full-screen-open header-link-icon" />
                                <i className="bx bx-exit-fullscreen full-screen-close header-link-icon d-none" />
                            </a> */}
                        </div>
                        <div className="header-element">
                            <a
                                href="#"
                                className="header-link dropdown-toggle"
                                id="mainHeaderProfile"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="outside"
                                aria-expanded="false"
                            >
                                <div className="d-flex align-items-center">                                    
                                    <Avatar name={admin1} />                                    
                                    <div className="d-sm-block d-none">
                                        <p className="fw-semibold mb-0 lh-1">{admin1}</p>
                                        {/* <span className="op-7 fw-normal d-block fs-11">Web Designer</span> */}
                                    </div>
                                </div>
                            </a>
                            <ul
                                className="main-header-dropdown dropdown-menu pt-0 overflow-hidden header-profile-dropdown dropdown-menu-end"
                                aria-labelledby="mainHeaderProfile"
                            >
                                {/* <li>
                                    <a className="dropdown-item d-flex" href="/">
                                        <i className="ti ti-user-circle fs-18 me-2 op-7" />
                                        Profile
                                    </a>
                                </li> */}
                                <li>
                                    <Link className="dropdown-item d-flex" to="/ChangePassword">
                                        <i className="ti ti-headset fs-18 me-2 op-7" />
                                        Change password
                                    </Link>
                                </li>
                                <li>
                                    <a className="dropdown-item d-flex" onClick={logoutuser} href="#">
                                        <i className="ti ti-logout fs-18 me-2 op-7" />
                                        Log Out
                                    </a>
                                </li>
                            </ul>
                        </div>
                        {/* <div className="header-element">
                            <a
                                href="#"
                                className="header-link switcher-icon"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#switcher-canvas"
                            >

                                <i className="bx bx-cog header-link-icon" />
                            </a>
                        </div> */}
                    </div>
                </div>
            </header>

        </>
    )
}

export default Header