import React, { useEffect,useState } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  Container,
} from "reactstrap";
import PageHeader from "../PageHeader";
import {
  AvForm,
  AvField,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import { useParams } from 'react-router-dom';
const Addpayment = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const api_url = process.env.REACT_APP_BASE_URL;
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const[status,setstatus]=useState('');
  const[paidamount,setPaidamount]=useState();
  const [updatedData,setUpdatedData]=useState();
  // const  handlestatuschange= (e)=>{ 
  //   setstatus(e.target.value);   
  //    console.log(e.target.value);
  //   }
  const measurefetchbyid= async(id)=>{
          try {
            const api_url = process.env.REACT_APP_BASE_URL;
            const response = await fetch(`${api_url}/fetchorder/${id}`);
              const data = await response.json();
              setUpdatedData(data);   
              setstatus(data.Payment_status);
              if(data.Payment_status==='Paid'){
                setIsButtonDisabled(true);     
              }              
              console.log("kkkkkfetchbyid",data);      
          } catch (error) {
            console.error('Error fetching customer data:', error.message);
          }        
      }
    useEffect(() => {
      measurefetchbyid(id)                     
    }, [id]);     
        const paymentstatus = async()=>{     
          const remainingAmount = updatedData.Totalamount - paidamount-updatedData.paidamount.reduce((total, data) => total + data.paid, 0)
          if (remainingAmount <= 0) {
        const response1 = await fetch(`${api_url}/updatepaymentstatus/${id}/${"Paid"}`,
                {
                    method: "PUT",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                });
                const data = await response1.json();
                console.log(data,"Dadad",data.status);
               // navigate("/payments");
              }else if(paidamount+updatedData.paidamount.reduce((total, data) => total + data.paid, 0)>0 && remainingAmount > -1){
                const response1 = await fetch(`${api_url}/updatepaymentstatus/${id}/${"Partially"}`,
                {
                    method: "PUT",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                });
                const data = await response1.json();
                console.log(data,"Dadad",data.status);
              }
              }   

  const submitForm = async (e, v) => {
     e.preventDefault();    
    try{
      const response = await fetch(`${api_url}/updateamount/${id}/${paidamount}`,
     { 
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }, 
     });
     measurefetchbyid(id)  
      if (response.ok) {
        const updatedData = await response.json();
       
        paymentstatus();
        navigate("/payments");
        Swal.fire({
          title: "Success!",
          text: "Order add succeessfully!",
          icon: "success",
        });
       
        // const remainingAmount = updatedData.Totalamount - updatedData.paidamount.reduce((total, data) => total + data.paid, 0)
        // if (remainingAmount === 0) {
        //   const response1 = await fetch(`${api_url}/updatepaymentstatus/${id}/${"Paid"}`,
        //   {
        //       method: "PUT",
        //       headers: {
        //           Accept: "application/json",
        //           "Content-Type": "application/json",
        //       },
        //   });
        //   console.log("paid");          
        // }
         // setUpdatedData((pre)=> pre.map((items) => 
        //         items._id === updatedData._id ? {...items,...updatedData} :items
        //         ));
        
       
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.error("Failed to submit form");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  return (
    <>
      <div className="main-content app-content">
        <Container fluid={true}>
          <PageHeader title="Add payment" />
          <Row>
            <Col xl={2}></Col>
            <Col xl={8}>
              <Card className=" custom-card">
                <CardHeader className="justify-content-between">
                  <CardTitle> Add Payment</CardTitle>
                </CardHeader>
                <CardBody>  
                   <AvForm onValidSubmit={submitForm}>
                    <Row className="accordion_css">              
                                             

                {updatedData ?(    
                           <Card className=" custom-card">                                             
                                 
                    <CardBody> 
                    <div className="row gy-3">
                    <img src="/assets/images/logo.png"
                            alt="Yunus Tailors"                          
                            style={{ width: '100px', height: 'auto' }}                             
                        />
                    {/* <h1 className="logoheading">Yunus Tailor Shop</h1> */}
    <div className="col-xl-12">
        <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">                
                <p>Yunus Tailors</p>
                <p>Rikabganj Road,Chowk</p>
                <p>Faizabad, 226024</p>
                <p>+91 9415918850</p>
                <p>+91 9935742210</p>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 ms-auto col-6 mt-sm-0 mt-3">
                <p>Customer Details :</p>
                <p>{updatedData.customername}</p>
                <p>{updatedData.address}</p>
                <p>+91 {updatedData.phone}</p>
            </div>
        </div>
    </div>
                          <div className="row bg-light mt-3 pt-2 pb-2">
                          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6">
                                  <p>Invoice ID :</p>
                                  <p>{updatedData.orderId}</p>
                              </div>
                          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6">
                                  <p>Date Issued :</p>
                                  <p>
                                      {new Date(updatedData.createdAt).toLocaleDateString('en-GB', {
                                          day: 'numeric',
                                          month: 'short',
                                          year: 'numeric',
                                      })}
                                      <span></span>
                                  </p>
                              </div>
                          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6">
                                  <p>Delivery Date :</p>
                                  <p> {new Date(updatedData.Delivery_Date).toLocaleDateString('en-GB', {
                                          day: 'numeric',
                                          month: 'short',
                                          year: 'numeric',
                                      })}</p>
                              </div>
                          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6">
                                  <p>Due Amount :</p>
                                  <p> ₹{updatedData.Totalamount-updatedData.paidamount.reduce((total, data) => total + data.paid, 0)}
                                
                                  </p>
                              </div>
                          </div>
                          <div className="col-xl-12">
                              <div className="table-responsive">
                                  <table className="table nowrap text-nowrap border mt-4">
                                      <thead>
                                          <tr>                      
                                                      <td className="product-quantity-container">Article Name</td>
                                                      <td className="product-quantity-container">Unit</td>
                                                      <td className="product-quantity-container">Price (in ₹)</td>
                                                      <td className="product-quantity-container">Total (in ₹)</td>
                                              </tr>
                                      </thead>
                                      <tbody>
                                      {updatedData.item.map((data, index) => (
                                              <tr key={index}>
                                                  <td className="product-quantity-container">{data.articlename}</td>
                                                  <td className="product-quantity-container">{data.Unit}</td>
                                                  <td className="product-quantity-container"> {data.price}</td>
                                                  <td className="product-quantity-container"> {data.totalofarticle}</td> 
                                              
                                              </tr>
                                          ))}                                            
                                          <br/>
                                        <tr>
                                          <td colSpan={2} />
                                          <th>Sub Total:</th>
                                          <td> {updatedData.amount}</td>
                                        </tr>
                                        <tr>
                                          <td colSpan={2} />
                                          <th>Advanced Payment:</th>
                                          <td>{updatedData.Advanced}</td>
                                        </tr>
                                        <tr>
                                          <td colSpan={2} />
                                          <th>Discount:</th>
                                          <td>{updatedData.Discount}%</td>
                                        </tr>
                                        <tr>
                                          <td colSpan={2} />
                                          <th>Total:</th>
                                          <td> {updatedData.Totalamount}</td>
                                        </tr>                                  
                                          
                                      </tbody>
                                  </table>                                 
                              </div>
                          </div> 

                          <div>
                          <Row>
                          <Col md={4}>
                            <table className="table  table-bordered border-primary">                            
                            <tbody>
                              <tr> Total Payments Details</tr>
                              <tr  class="table-active">
                              <th>Paid date</th>
                              <th>Paid amount</th>                              
                            </tr>{updatedData.paidamount.map((data, index) => (
                              <tr key={index}>                                                                
                                  <td>{new Date(data.Paiddate).toLocaleDateString('en-GB', {
                                          day: 'numeric',
                                          month: 'short',
                                          year: 'numeric',
                                      })}</td>
                                      <td>{data.paid}</td>   
                              </tr>                             
                          ))} 
                           <tr>
                                <th  class="table-active">Total Paid </th>
                                <th  class="table-active">
                             {updatedData.paidamount.reduce((total, data) => total + data.paid, 0)}
                            </th></tr>
                              </tbody>
                            </table>                           
                                  </Col>
                        <Col md={4}>
                        <AvField 
                            type="number"
                            name="payment"
                            label="Paid amount" 
                            min={1}
                            //max={100}
                            max={updatedData.Totalamount - updatedData.paidamount.reduce((total, data) => total + data.paid, 0)}
                            onChange={(e) => setPaidamount(e.target.value)}
                          />   <Col lg={12}>
                             <FormGroup className="mb-0 text-center">
                           <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1"
                              disabled={isButtonDisabled}
                            >
                              Paid
                            </Button>
                          </div>
                          </FormGroup></Col>
                        </Col>
                        <Col md={4}><label> Payment Status    </label>    <br/>                 
                          <span className={updatedData.Payment_status === 'Paid' ? 'badge bg-success-transparent' : 
           updatedData.Payment_status === 'Unpaid' ? 'badge bg-danger-transparent' :
           'badge bg-warning'}>
                            {updatedData.Payment_status}
                          </span>
                        </Col>

                       
                      </Row> </div>                                                                                                  
                      </div>
                    </CardBody>           
                    </Card>  ) :( <div class="spinner-border text-info" role="status">
  <span class="visually-hidden">Loading...</span>
</div>)}                     
                     </Row> 
                     </AvForm>             
                </CardBody>
                </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Addpayment



  {/* <AvField type="select" name="status"  label="Payment status"
                       value={status} 
                      onChange={(e)=>setstatus(e.target.value)} >
                             <option selected>Unpaid</option>
                             <option value ="Successfull" >Paid</option>
                                    </AvField> */}