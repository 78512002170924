import React from 'react'
import { Row, Col, Card, CardHeader, CardBody, FormGroup, Button, CardTitle, Container } from "reactstrap"
import PageHeader from '../PageHeader'
import { AvForm, AvField,} from "availity-reactstrap-validation"
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'

const AddCustomer = () => {
    const navigate = useNavigate();

const submitForm = async (e, v) => {
        e.preventDefault();
        try {
            let payload = {
                name: v.name,
                phone:v.phone,
                email:v.email,
                reference:v.reference,
                address1:v.address1,
                address2:v.address2,
            }
            console.log(payload, "PL");
            const api_url = process.env.REACT_APP_BASE_URL;
              ///     `${api_url}
            const response = await fetch(`${api_url}/addcustomer`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
           
            if (response.ok) {
                Swal.fire({
                    title: "Success!",
                    text: "Customer add succeessfully!",
                    icon: "success"
                  });
                // const responseData = await response.json();
                // console.log('Response Body:', responseData);
                // console.log(response.statusText, 'successfully');
                navigate('/AllCustomers')
            }
             else
             {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                  });
                console.error('Failed to submit form');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    }
    return (
        <>
            <div className="main-content app-content" >
                <Container fluid={true}>
                    <PageHeader title="All  Customers" />
                    <Row>
                        <Col xl={1}></Col>
                        <Col xl={10}>
                            <Card className=" custom-card">
                                <CardHeader className="justify-content-between">
                                    <CardTitle> Add  Customer
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <AvForm onValidSubmit={submitForm}>
                                        <Row className='accordion_css'>
                                            <Col md={2}>
                                                <p> Name:</p>
                                            </Col>
                                            <Col md={4}>
                                                <AvField
                                                    name="name"
                                                    type="text"
                                                    validate={{ required: { value: true } }}
                                                    className="form-control"
                                                    placeholder="Enter  Name"
                                                />
                                            </Col>
                                            <Col md={2}>
                                                <p>Phone:</p>
                                            </Col>
                                            <Col md={4}>
                                                <AvField
                                                    name="phone"
                                                    type="text"
                                                    pattern="[0-9]*"
                                                    maxLength="10"
                                                    validate={{
                                                        required: { value: true, errorMessage: 'Phone number is required' },
                                                        maxLength: { value: 10, errorMessage: 'Phone number must be 10 digits' },
                                                        pattern: { value: /^[0-9]*$/, errorMessage: 'Please enter a valid phone number' }
                                                    }}
                                                    
                                                    className="form-control"
                                                    placeholder="Enter Phone"
                                                />
                                            </Col>
                                            <Col md={2}>
                                                <p>Email:</p>
                                            </Col>
                                            <Col md={4}>
                                                <AvField
                                                    name="email"
                                                    type="email"
                                                    validate={{ required: { value: true } }}
                                                    className="form-control"
                                                    placeholder="Enter Email"
                                                />
                                            </Col>
                                            <Col md={2}>
                                                <p>Reference:</p>
                                            </Col>
                                            <Col md={4}>
                                                <AvField
                                                    name="reference"
                                                    type="text"
                                                    validate={{ required: { value: true } }}
                                                    className="form-control"
                                                    placeholder="Enter Reference"
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <AvField
                                                    className="form-control"
                                                    type="textarea"
                                                    placeholder="Enter Address Line 1"
                                                    name="address1"
                                                    validate={{ required: { value: true } }}
                                                    errorMessage="Address cannot be empty"
                                                />
                                            </Col>
                                            <Col md={6}>
                                            <AvField
                                                    className="form-control"
                                                    type="textarea"
                                                    placeholder="Enter Address Line 2"
                                                    name="address2"
                                                    validate={{ required: { value: true } }}
                                                    errorMessage="Address cannot be empty"
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12}>
                                                <FormGroup className="mb-0 text-center mt-5">
                                                    <div>
                                                        <Button
                                                            type="submit"
                                                            color="primary"
                                                            className="mr-1"
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </AvForm>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row></Container>
            </div>
        </>
    )
}

export default AddCustomer
