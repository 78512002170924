import React, { useEffect } from 'react'
import { Outlet, Navigate } from 'react-router-dom'

function Protected(props) {
    const { component } = props;
    // const naviagte = useNavigate();
    let auth = { 'token': true }


    // useEffect(() => {
    //     let login = localStorage.getItem('login')
    //     if (!login) {
    //         naviagte('/')
    //     }
    // });

    return (
        auth.token ? <Outlet /> : <Navigate to="/" />
    )
}
export default Protected