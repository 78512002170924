import React, { useEffect,useState} from 'react'
import { Row, Col, Card, CardHeader, CardBody, CardTitle, Container } from "reactstrap"

import { useParams } from 'react-router-dom';


const InvoicePrint = () => {
  const { id } = useParams();
  const handlePrint = () => {
    let pbtn = document.getElementById("printbtn");
    pbtn.style.display = "none";
    window.print();
    pbtn.style.display="block";
  };
  const [updatedData,setUpdatedData]=useState();
  
  const measurefetchbyid= async(id)=>{
          try {
            const api_url = process.env.REACT_APP_BASE_URL;
            const response = await fetch(`${api_url}/fetchorder/${id}`);
              const data = await response.json();
              setUpdatedData(data);         
          } catch (error) {
            console.error('Error fetching customer data:', error.message);
          }        
      }
   
      console.log(updatedData);
  useEffect(()=>{
    measurefetchbyid(id)
  },[id]);

  return (
    <>          
                 <div className="main-content app-content">
                     <Container fluid={true}>   
                     <Row>
                     <Col xl={2}></Col>  
                     <Col xl={6} >

                     {updatedData ?(    
                           <Card className=" custom-card">                                                
                        <CardHeader className="justify-content-between">
                    <CardTitle> Invoice {updatedData.orderId}</CardTitle>
                    </CardHeader>  
                    <CardBody> 
                       <div className="row gy-3"  >
                       <img src="/assets/images/logo.png"
                            alt="Yunus Tailors"                          
                            style={{ width: '100px', height: 'auto' }}                             
                        />
                           
                    {/* <h1 className="logoheading">Yunus Tailor Shop</h1> */}
    <div className="col-xl-12" >
        <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">                
                <p>Yunus Tailors</p>
                <p>Rikabganj Road,Chowk</p>
                <p>Faizabad, 226024</p>
                <p>+91 9415918850</p>
                <p>+91 9935742210</p>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 ms-auto col-6 mt-sm-0 mt-3">
                <p>Customer Details :</p>
                <p>{updatedData.customername}</p>
                <p>{updatedData.address}</p>
                <p>+91 {updatedData.phone}</p>
            </div>
        </div>
    </div>
    <div className="row bg-light mt-3 pt-2 pb-2">
     <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6">
            <p>Invoice ID :</p>
            <p>{updatedData.orderId}</p>
        </div>
     <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6">
            <p>Date Issued :</p>
            <p>
                {new Date(updatedData.createdAt).toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                })}
                <span></span>
            </p>
        </div>
     <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6">
            <p>Delivery Date :</p>
            <p> {new Date(updatedData.Delivery_Date).toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                })}</p>
        </div>
     <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6">
            <p>Due Amount :</p>
            <p> ₹{updatedData.Totalamount}
           
            </p>
        </div>
    </div>
    <div className="col-xl-12">
        <div className="table-responsive">
            <table className="table text-nowrap">
                <thead>
                    <tr>                      
                                <td className="product-quantity-container">Article Name</td>
                                <td className="product-quantity-container">Unit</td>
                                <td className="product-quantity-container">Price (in ₹)</td>
                                <td className="product-quantity-container">Total (in ₹)</td>
                        </tr>
                </thead>
                <tbody>
                {updatedData.item.map((data, index) => (
                        <tr key={index}>
                             <td className="product-quantity-container">{data.articlename}</td>
                             <td className="product-quantity-container">{data.Unit}</td>
                             <td className="product-quantity-container"> {data.price}</td>
                             <td className="product-quantity-container"> {data.totalofarticle}</td> 
                        
                        </tr>
                    ))}
                     <br/>
                  <tr>
                    <td colSpan={2} />
                    <th>Sub Total:</th>
                    <td> {updatedData.amount}</td>
                  </tr>
                  <tr>
                    <td colSpan={2} />
                    <th>Advanced Payment:</th>
                    <td>{updatedData.Advanced}</td>
                  </tr>
                  <tr>
                    <td colSpan={2} />
                    <th>Discount:</th>
                    <td>{updatedData.Discount}%</td>
                  </tr>
                  <tr>
                    <td colSpan={2} />
                    <th>Total:</th>
                    <td> {updatedData.Totalamount}</td>
                  </tr>    
                  
                </tbody>
            </table>
        </div>
    </div>
                                


                            

                           
                       
    {/* <div className="col-xl-12">
                                    <div>
                                        <label htmlFor="invoice-note" className="form-label">
                                            Note:
                                        </label>
                                        <textarea
                                            className="form-control form-control-light"
                                            id="invoice-note"
                                            rows={3}
                                            defaultValue={"Once the invoice has been verified by the accounts payable team and recorded, the only task left is to send it for approval before releasing the payment\n                                                "} />
                                    </div>
                                </div> */}
</div>


                    </CardBody>           

                    </Card>  ) :( <div class="spinner-border text-info" role="status">
  <span class="visually-hidden">Loading...</span>
</div>)}
                    <div class="card-header d-md-flex d-block">
                    <div class="ms-auto mt-md-0 mt-2">
                                <button className="btn btn-sm btn-secondary me-1" id="printbtn" onClick={(handlePrint)}  >
                                    Print
                                    <i className="ri-printer-line ms-1 align-middle d-inline-block" />
                                </button>
                                
                                </div></div>

                     </Col>

                     </Row>
                         
                     
                    </Container>
                                
                </div>           
           
                    
            
    </>
  )
}
export default InvoicePrint