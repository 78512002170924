import React, { useEffect,useState} from 'react'
import { Row, Col, Card, CardHeader, CardBody, CardTitle, Container } from "reactstrap"
import PageHeader from '../PageHeader';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
const OrdersPage = () => {
   
    const api_url = process.env.REACT_APP_BASE_URL;
    const [searchTerm, setSearchTerm] = useState('');
    const currentDate = new Date();

  //  const [Delivery_status,setDelivery_status]=useState();
    
    const handledeliveryStatuschange = async (event, orderId) => {
        console.log(event.target.value, orderId, "before");
        const d_status = event.target.checked; // Use event.target.checked to get the status of the checkbox
        console.log(d_status, orderId, "after");
        try {
            const result = await Swal.fire({
                title: "Changing Delivery Status",
                text: "You  can chang delivery status!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm Delivery Status!"
              });          
              if (result.isConfirmed) {
            const response = await fetch(`${api_url}/updatedeliverystatus/${orderId}/${d_status}`,
                {
                    method: "PUT",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                });
            if (response.ok) {
                Swal.fire({
                    title: "Updated!",
                    text: "Your Delivery Status has been Updated.",
                    icon: "success"
                  });
            } else {
                console.error("Failed to update delivery status.");
                Swal.fire({
                    title: "Error!",
                    text: "Failed to Update the status.",
                    icon: "error"
                  });
            }
        }} catch (error) {
            Swal.fire({
                title: "Error!",
                text: "An unexpected error occurred.",
                icon: "error"
              });
            console.error("Error updating delivery status:", error.message);
            // Handle error if needed
        }
    };

    const navigate = useNavigate();
    const [OrderData, setOrderData] = useState([]);
    const fetchdataorder = async () => {
      try {
        const response = await fetch(`${api_url}/fetchdataorder`);
        const data = await response.json();
        setOrderData(data);
      } catch (error) {
        console.log(error.message);
      }
    };
    useEffect(() => {
      fetchdataorder();
    },); 
    const deletedata= async(id)=>{
        try {
            const result = await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!"
              });          
              if (result.isConfirmed) {
              const response = await fetch( `${api_url}/deleteorder/${id}`, {
                    method: 'DELETE',
                    headers: {
                      'Content-Type': 'application/json',
            },
          });
          console.log(response.status, "PLdelete",response.ok);
          if (response.ok) {
           const updatedData = OrderData.filter((item) => item._id !== id);
           setOrderData(updatedData);
           Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success"
          });
          } else {
            Swal.fire({
                title: "Error!",
                text: "Failed to delete the element.",
                icon: "error"
              });
            console.error('Failed to delete article from server');
          }}
        } catch (error) { 
           Swal.fire({
            title: "Error!",
            text: "An unexpected error occurred.",
            icon: "error"
          });
          console.error('Error deleting article:', error.message);
        }
      };
    const filterData = OrderData.filter(a => a.orderId.toLowerCase().includes(searchTerm.toLowerCase())|| 
                                             a.customername.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                             a.phone.toLowerCase().includes(searchTerm.toLowerCase()));

    const [filterdata,setfilter]=useState([]);
const handleFilter=(selecteds)=>{
    console.log(selecteds);
    const x= OrderData.filter(
    (item) => item.Payment_status.toLowerCase().includes(selecteds.toLowerCase())
    );
    setfilter(x);
};
const renderedData = filterdata.length > 0 ? filterdata : filterData;

const [currentPage, setCurrentPage] = useState(1);
const startIndex = (currentPage - 1) * 10;
const endIndex = currentPage * 10;
// Function to the next page
const nextPage = () => {
  setCurrentPage((prevPage) => prevPage + 1);
};
// Function to  the previous page
const prevPage = () => {
  setCurrentPage((prevPage) => prevPage - 1);
};
    return (
        <>
            <div className="main-content app-content " id='backscreen' >
                <Container fluid={true}>
                    <PageHeader title="All  Orders" />
                    <Row>
                        <Col xl={12}>
                            <Card className=" custom-card">
                                <CardHeader className="justify-content-between">
                                    <CardTitle> All  Orders

                                    </CardTitle>
                                    <br />
                                    <div className="d-flex flex-wrap gap-4">
                                        <div>
                                            <input
                                                className="form-control form-control-sm"
                                                type="text"
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                placeholder="Search Here"
                                                aria-label=".form-control-sm example"
                                            />
                                        </div>
                                   
                                    <div className="btn-list">
                                        <div className="">
                                            <button type="button" onClick={() => navigate('/Addorder')} className="btn btn-primary" disabled="">
                                                Add Order <i className='fa fa-plus-circle'></i></button>
                                        </div>
                                    </div> </div>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive">
                                        <table className="table table-responsive table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Order ID</th>
                                                    <th scope="col">Customer Name</th>
                                                    <th scope="col">Phone</th>  
                                                    <th scope="col"  style={{ width: '150px' }}>Order Status </th>            
                                                                                            
                                                    {/* <th scope="col" style={{ width: '150px' }}>
                                                       <p> Payment Status &emsp;<select name="status"  defaultValue="Paid" 
                                                        id="status" className=' border selectintable'onChange={(e) => handleFilter(e.target.value)}>
                                                            <option selected value="Paid">Paid</option>
                                                            <option value="Unpaid">Unpaid</option>
                                                        </select></p>
                                                    </th> */}
                                                    
                                                    <th  scope="col"  style={{ width: '150px' }} >Advanced Payment</th>
                                                    <th scope="col">Total Amount </th>
                                                    <th scope="col">Due Amount</th>
                                                    <th scope="col">Delivery Date</th>
                                                    <th scope="col"  style={{ width: '150px' }} >Delivery Status</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {renderedData.slice(startIndex, endIndex).map((data,index)=>(
                                                     <tr key={data._id}>
                                                     <th>{startIndex+index+1}</th>                                                    
                                                        <td>{data.orderId}</td>
                                                        <td>{data.customername} </td>
                                                        <td>{data.phone}</td>
                                                        <td>{data.Delivery_status === true ? (
                                                            <span className="btn btn-sm btn-success-light">Delivered</span>
                                                        ) : (
                                                            Math.floor((currentDate.setHours(0, 0, 0, 0)-new Date(data.createdAt).setHours(0, 0, 0, 0) ) / (1000 * 60 * 60 * 24)) === 0 ? (
                                                                <span className="btn btn-sm btn-info-light">Order Placed</span> 
                                                            ) : (
                                                                Math.floor((currentDate.setHours(0, 0, 0, 0)-new Date(data.Delivery_Date).setHours(0, 0, 0, 0) ) / (1000 * 60 * 60 * 24)) > 0 ? (
                                                                    <span className="btn btn-sm btn-danger-light">Overdue {Math.floor((currentDate.setHours(0, 0, 0, 0)-new Date(data.Delivery_Date).setHours(0, 0, 0, 0) ) / (1000 * 60 * 60 * 24))} days</span> 
                                                                ) : (
                                                                    <span className="btn btn-sm btn-warning-light">In Progress</span>
                                                                )
                                                            )
                                                        )}</td>
{/* {data.Delivery_status === true ? (
                                                                <span className="btn btn-sm btn-success-light">Delivered</span>
                                                            ) : (
                                                                Math.floor((currentDate.setHours(0, 0, 0, 0)-new Date(data.Delivery_Date).setHours(0, 0, 0, 0) ) / (1000 * 60 * 60 * 24)) > 0 ? (
                                                                    <span className="btn btn-sm btn-danger-light">Overdue {Math.floor((currentDate.setHours(0, 0, 0, 0)-new Date(data.Delivery_Date).setHours(0, 0, 0, 0) ) / (1000 * 60 * 60 * 24))} days</span> 
                                                                ) : (
                                                                    <span className="btn btn-sm btn-warning-light">In Progress</span>
                                                                )  )}*/}
                                                          
                                                         <td><i className='fa fa-inr'></i>{data.Advanced}</td>
                                                        <td><i className='fa fa-inr'></i>{data.amount}</td>
                                                        <td><i className='fa fa-inr'></i>{data.Totalamount}</td>
                                                        <td>{new Date(data.Delivery_Date).toLocaleDateString('en-GB', {
                                                                                                                day: 'numeric',
                                                                                                                month: 'short',
                                                                                                                year: 'numeric',
                                                                                                            })}</td>
                                                        <td className='align-center justify-content-center'> &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <input
                                                            type="checkbox"
                                                            class="form-check-input"
                                                            id={`Del_status_${data.orderId}`}  // Ensure unique ID for each checkbox
                                                            name={`Del_status_${data.orderId}`}  // Optional: Include orderId in name attribute for identification
                                                            value={data.Delivery_status}
                                                            checked={data.Delivery_status}
                                                            onChange={(event) => handledeliveryStatuschange(event, data.orderId)}
                                                        />
                                                    </td>
                                                        <td>    
                                                            <div className="hstack gap-2 flex-wrap">                                                  
                                                             <a className="btn btn-primary-light btn-icon btn-sm"
                                                              title="Print Invoice"
                                                              onClick={() =>{
                                                              window.open(`/InvoicePrint/${data.orderId}`, '_blank')}}>
                                                             <i className="ri-printer-line"></i></a>

                                                             <a onClick={() => deletedata(data._id)}
                                                                        class="btn btn-icon btn-sm btn-danger-transparent rounded-pill"><i
                                                                            class="ri-delete-bin-line"></i></a>
                                                           </div>
                                                        </td>
                                                    </tr>
                                                ))}                                       
                                            </tbody>
                                        </table>
                                         <div className="pagination justify-content-end mb-0 mt-4 ">
                                                <button
                                                className="btn btn-outline-secondary ml-2"
                                                onClick={prevPage}
                                                disabled={currentPage === 1}
                                                >
                                                Previous
                                                </button>
                                                <button
                                                className="btn btn-outline-secondary ml-2"
                                                onClick={nextPage}
                                                disabled={endIndex >= renderedData .length}
                                                >
                                                Next
                                                </button>
                                          
                                            </div>
                                    </div>
                                  

                                </CardBody>
                            </Card>
                        </Col>
                    </Row></Container>


            </div>
      
        </>
    )
}

export default OrdersPage
