import React, { useState ,useEffect,useRef} from 'react'
import PageHeader from '../PageHeader'
import { Row, Col, Card, CardHeader, CardBody, FormGroup, Button, CardTitle, Container } from "reactstrap"
import { AvForm, AvField, } from "availity-reactstrap-validation"
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure
} from '@chakra-ui/react'
import Swal from 'sweetalert2'

const AddElemeasure = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const refContainer = useRef(null)
    const [customerData,setcustomerData]=useState([]);
    const [article_name, setArticle_name] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [mapmeasurement, setmeasurementmap] = useState([]);
    const [updatedMeasureData, setUpdatedMeasureData] =useState();
    const [loading, setLoading] = useState(false);
    const [phoneno,setPhone]=useState();
    const api_url = process.env.REACT_APP_BASE_URL;
    const fetchcustomername = async () => {
          try {
            const response = await fetch(`${api_url}/customername`);       
            const data = await response.json();
            setcustomerData(data);     
          } catch (error) {
            console.log(error.message);       
          }};
          const fetchphone = async (selectedCustomer_name) => {
            try {
              const response = await fetch(`${api_url}/customer/${selectedCustomer_name}`);       
              const data = await response.json();
              setPhone(data.phone);  
              console.log(data.phone);   
            } catch (error) {
              console.log(error.message);       
            }};
     
          
    const fetchArticlename = async () => {
          try {
            const response = await fetch(`${api_url}/articlename`);   
            const data = await response.json();
            setArticle_name(data);      
          } catch (error) {
            console.log(error.message);       
          }};
    const fetchmeasuremap = async () => {
          try {
            const response = await fetch(`${api_url}/addelementmeasure`);
            const data = await response.json();
            setmeasurementmap(data); 
          } catch (error) {
            console.log(error.message);    
          }};
          const User=   localStorage.getItem('username');
        
    useEffect(() => {
        fetchcustomername();    
        fetchArticlename();
        fetchmeasuremap();
        
      }, []);
     const[articlepartname,setarticlepartname]=useState([])
     const fetchpartname = async (selectedArticle_name,parts) => {
        try {
          if (!selectedArticle_name || !parts) { return;
          }
          const response = await fetch(`http://localhost:5000/article/${selectedArticle_name}/${parts} `,);    
          if (response.ok) {
            const data = await response.json();
            setarticlepartname(data);
          } else {
            console.error('Failed to fetch element data', response.status);
          }
        } catch (error) {
          console.error('Error fetching element data:', error.message);
        }
      };
const[selectedCustomer_name,setselectedCustomer_name]=useState('');
const[selectedArticle_name,setselectedArticle_name]=useState();
const[selectedpartname,setselectedpartname]=useState();
const [parts, selectedParts] = useState();
const[remarks,setremarks]=useState();
const handlecustomerchange =(e)=>{ setselectedCustomer_name(e.target.value);console.log('Selected Customer name:', e.target.value);}
const handlearticlechange =(e)=>{ setselectedArticle_name(e.target.value);console.log('Selected Article name:', e.target.value);}
const handlepartchange=(e)=>{
  selectedParts(e.target.value);
  console.log('Selected Part Type:', e.target.value);
   //setselectedpartname(null); 
   setLoading(false);
   setshow(false);
}
const handleremarks=(e)=>{setremarks(e.target.value);}
const handlepartname=(e)=>{setselectedpartname(e.target.value);}
useEffect(() => { 
  if(selectedCustomer_name){
    fetchphone(selectedCustomer_name); 
  }
}, [selectedCustomer_name]);  
useEffect(() => { 
        if(selectedArticle_name && parts){        
            fetchpartname(selectedArticle_name,parts); 
          }     
         }, [selectedArticle_name,parts]);  

     const [elementData, setElementData] = useState([]);
     const [show,setshow]=useState(false);
const fetchdata= async (selectedArticle_name, parts,selectedpartname) => {
    try {
      if (!selectedArticle_name || !parts || !selectedpartname) {   
            return;
      }
      const response = await fetch(`${api_url}/elementmapping/${selectedArticle_name}/${parts}/${selectedpartname} `,);        
      if (response.ok) {
        const data = await response.json();
        setElementData(data);
        setshow(true);
     
      
      } else {
        console.log('Failed to fetch element data', response.status);
      }
    } catch (error) {
      console.log('Error fetching element data:', error.message);
    }
  };
  useEffect(() => { 
    checkData(selectedCustomer_name,selectedArticle_name,parts, selectedpartname);
       }, [selectedCustomer_name, selectedArticle_name,parts,selectedpartname]);
  
 const [checkdata,setcheckdata]=useState([]);
  const checkData = async (selectedCustomer_name,selectedArticle_name,parts, selectedpartname) => {
  try {
    if (!selectedCustomer_name || !selectedArticle_name || !parts|| !selectedpartname) {
      return;
    }
      const checkResponse = await fetch(`${api_url}/check/${selectedCustomer_name}/${selectedArticle_name}/${parts}/${selectedpartname}`);
     if(checkResponse.ok) {   
      fetchdata(selectedArticle_name, parts,selectedpartname);        
      Swal.fire({
        title: "Data Found",
        text: "Your can view and update it.",
        icon: "warning"
      });     
      const Data = await checkResponse.json();
      document.getElementById("myBtn").innerHTML = "Update"; 
      console.log("alread exist" ,Data);
      setcheckdata(Data);
      setLoading(true);    
      
      setshow(false);
    } else {
      console.log('no existing data', checkResponse.status);
      setLoading(false);
      document.getElementById("myBtn").innerHTML = "Submit"; 
      fetchdata(selectedArticle_name, parts,selectedpartname);       
    }
  } catch (error) {
    console.error('Error fetching data:', error.message);
  }
};

const updateremarks=(e)=>{
  setupremark(e.target.value);
}
const[upremark,setupremark]=useState();
useEffect(() => {
  setupremark(checkdata[0]?.remarks || ''); // Using optional chaining to handle undefined or null
}, [checkdata[0]]);

const handleMeasureupdateChange = (e, measurename) => {
  const newValue = e.target.value;

  // Update the measurevalue in the measureValuesMap
  const updatedMeasure = measureValuesMap.get(measurename);
  if (updatedMeasure) {
    updatedMeasure.measurevalue = newValue;
    measureValuesMap.set(measurename, updatedMeasure);
  } else {
    // If the measurename doesn't exist in measureValuesMap, add it with the new value
    measureValuesMap.set(measurename, { measurename, measurevalue: newValue });
  }

  // Create a new array representation of measureValuesMap
  const newArray = Array.from(measureValuesMap.values()).map(({ measurename, measurevalue }) => ({
    measurename,
    measurevalue
  }));

  console.log(measureValuesMap, "fssfsf"); // Log the updated measureValuesMap
  console.log(newArray, "newArray"); // Log the new array representation
};

const measureValuesMap = new Map();

// Create a set to store common measure names
const commonMeasureNames = new Set();

// Iterate through elementData
elementData.forEach((el) => {
  if (el.element && el.element.length > 0) {
    el.element.forEach((innerEl) => {
      commonMeasureNames.add(innerEl);
    });
  }
});

// Iterate through checkdata[0].measure and populate measureValuesMap for common measures
if (checkdata[0] && checkdata[0].measure && checkdata[0].measure.length > 0) {
  checkdata[0].measure.forEach((data) => {
    if (commonMeasureNames.has(data.measurename)) {
      measureValuesMap.set(data.measurename, { measurename: data.measurename, measurevalue: data.measurevalue });
    }
  });
}

// For non-common measures in elementData, initialize measureValuesMap with a value of 0
elementData.forEach((el) => {
  if (el.element && el.element.length > 0) {
    el.element.forEach((innerEl) => {
      if (!measureValuesMap.has(innerEl)) {
        measureValuesMap.set(innerEl, { measurename: innerEl, measurevalue: 0 });
      }
    });
  }
});

const onvalidsubmit = async(e,v) => {
      e.preventDefault();   
      if(loading){
        let payload = {
          customername: selectedCustomer_name,
          phone:phoneno,
          articlename: selectedArticle_name,               
          type: parts,
          partname:selectedpartname,
          measure: Array.from(measureValuesMap.values()).map(({ measurename, measurevalue }) => ({
            measurename,
            measurevalue
          })),
          remarks: upremark,
          admin:User
        };
        try{
          const result = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, update it!"
          });
          if (result.isConfirmed) {
            const response = await fetch(`${api_url}/updatesingleelemntmeasure/${checkdata[0]._id}`, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
              });    
              if (response.ok) {       
                const updatedData = await response.json();
              //  console.log("updates:",updatedData);
                document.getElementById("myBtn").innerHTML = "Submit"; 
                setUpdatedMeasureData(updatedData); 
              setremarks('');
              setLoading(false)    
              setshow(false);
                Swal.fire({
                  title: "Updated!",
                  text: "Your file has been updated.",
                  icon: "success"
                });           
                setmeasurementmap((pre)=> pre.map((items) => 
                items._id === updatedData._id ? {...items,...updatedData} :items
                ));
              } else {
                Swal.fire({
                  title: "Error!",
                  text: "Failed to update the element.",
                  icon: "error"
                });
                console.error(`Failed to update data. Status: ${response.status}`);
              }}
          }catch (error) {
              Swal.fire({
                title: "Error!",
                text: "An unexpected error occurred.",
                icon: "error"
              });
              console.error('Error deleting article:', error.message);
            }
      }   
      else{
      try {
      let payload = {
        customername: selectedCustomer_name,
        phone:phoneno,
        articlename: selectedArticle_name,   
        type: parts,
        partname:selectedpartname,
        measure: [],
        remarks: remarks,
        admin:User
      };
      elementData.forEach((el, index) => {
        el.element.forEach((innerEl, innerIndex) => {
          payload.measure.push({
            measurename: innerEl,
            measurevalue: v[`measurevalue${index}${innerIndex}`],
          });
        });
      });
          console.log(payload, "PL"); 
          const response = await fetch(`${api_url}/addelementmeasure`, {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(payload),
            });
         //   console.log(response.status, "PL");
            if (response.ok) {
              Swal.fire({
                title: "Success!",
                text: "Element Measurement added succeessfully!",
                icon: "success"
              });
                const responseData = await response.json();
                console.log('Response Body:', responseData);
                console.log(response.statusText, 'successfully');             
                fetchmeasuremap();
                setmeasurementmap((presdata)=> presdata.map((data) => 
                data._id === responseData._id ? {...data,...responseData} :data));
                setremarks('');
                setshow(false);
                setLoading(false);
               // checkData(selectedCustomer_name,selectedArticle_name, selectedpartname);
          
              //    refContainer.current.reset()
                    
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
              });
                console.error('Failed to submit form');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }   }
    } 
    const filteredmapmeasurement = selectedCustomer_name.toLowerCase() === 'all'
    ? mapmeasurement
    : mapmeasurement.filter(
        a => 
        //a.customername.toLowerCase().includes(searchTerm.toLowerCase()) ||
          //   a.phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
             a.customername.toLowerCase().includes(selectedCustomer_name.toLowerCase() ||searchTerm.toLowerCase())
      );
   

const deletedata = async (id) => {
          try {
            const result = await Swal.fire({
              title: "Are you sure?",
              text: "You won't be able to revert this!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, delete it!"
            }); 
            if (result.isConfirmed) {
              const response = await fetch(`${api_url}/addelementmeasure/${id}`, {
                method: 'DELETE',
                headers: {
                  'Content-Type': 'application/json',
                },
              });
              if (response.ok) {             
                const updatedData = mapmeasurement.filter((item) => item._id !== id);
                setmeasurementmap(updatedData);
                Swal.fire({
                  title: "Deleted!",
                  text: "Your file has been deleted.",
                  icon: "success"
                });
              } else {
                Swal.fire({
                  title: "Error!",
                  text: "Failed to delete the element.",
                  icon: "error"
                });
                console.error('Failed to delete article from server');
              }
            }
          } catch (error) {
            Swal.fire({
              title: "Error!",
              text: "An unexpected error occurred.",
              icon: "error"
            });
            console.error('Error deleting article:', error.message);
          }
        }; 
const handleFilter = (filterType) => {
        if (filterType === 'New') {
            const sortedData = [...filteredmapmeasurement].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
            setmeasurementmap(sortedData);  
        } else  if (filterType === 'sortbyname') {
            const sortedData = [...filteredmapmeasurement].sort((a, b) => a.customername.localeCompare(b.customername));
            setmeasurementmap(sortedData);
        }
      };

const measurefetchbyid= async(id)=>{
      try {
          const response = await fetch(`${api_url}/singleelemntmeasure/${id}`);
          if (response.ok) {
            const data = await response.json();
            setUpdatedMeasureData(data);
          } else {
            console.error(`Failed to fetch customer data. Status: ${response.status}`);
          }
        } catch (error) {
          console.error('Error fetching customer data:', error.message);
        }    
      console.log(updatedMeasureData);
    }
const handleDataChange = (e, fieldName) => {
    const { value } = e.target;
    setUpdatedMeasureData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };
const handleMeasureChange = (e, measureId) => {
      const { value } = e.target;
    
      const numericValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value);
      const measureIndex = updatedMeasureData.measure.findIndex(
        (measure) => measure._id === measureId
      );
      if (measureIndex !== -1) {
        setUpdatedMeasureData((prevData) => {
          const updatedMeasures = [...prevData.measure];
          updatedMeasures[measureIndex] = {
            ...updatedMeasures[measureIndex],
            measurevalue: numericValue,
          };
          return {
            ...prevData,
            measure: updatedMeasures,
          };
        });
      }};

const onupdateform = async(id) => {
      try{
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, update it!"
      });
      if (result.isConfirmed) {
        const response = await fetch(`${api_url}/updatesingleelemntmeasure/${id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedMeasureData),
          });    
          if (response.ok) {       
            const updatedData = await response.json();
            setUpdatedMeasureData(updatedData);     
            Swal.fire({
              title: "Updated!",
              text: "Your file has been updated.",
              icon: "success"
            });           
            setmeasurementmap((pre)=> pre.map((items) => 
            items._id === updatedData._id ? {...items,...updatedData} :items
            ));
          } else {
            Swal.fire({
              title: "Error!",
              text: "Failed to update the element.",
              icon: "error"
            });
            console.error(`Failed to update data. Status: ${response.status}`);
          }}
      }catch (error) {
          Swal.fire({
            title: "Error!",
            text: "An unexpected error occurred.",
            icon: "error"
          });
          console.error('Error deleting article:', error.message);
        }
        console.log(updatedMeasureData);
    }
    return (
        <>
            <div className="main-content app-content">
                <Container fluid={true}>
                    <PageHeader title="Add Element Measurement" />
                    <Row>
                        <Col xl={12}>
                            <Card className=" custom-card">
                                <CardHeader className="justify-content-between">
                                    <CardTitle>Add Element Measurement</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <AvForm onValidSubmit={onvalidsubmit}  ref={refContainer}>
                                        <Row className='accordion_css'>
                                            <Col md={3}>
                                                <AvField type="select" name="customer_name" value={selectedCustomer_name} onChange={handlecustomerchange} label="Custome Name" >
                                                    <option selected>all</option>
                                                       {customerData.map((type, index)=>(
                                                      <option key={index} value={type}>{type}</option> ))}     
                                                </AvField>
                                            </Col>
                                            <Col md={3}>
                                            <AvField type="select" value={selectedArticle_name} onChange={handlearticlechange} name="article_name" label="Article Name">
                                                <option>Select</option>
                                               
                                                     {article_name.map((type, index) => ( 
                                                    <option key={index} value={type}>{type}</option>
                                                    ))}
                                            </AvField>
                                            </Col>
                                            <Col md={3}>
                                                <AvField type="select" name="parts"  value={parts} onChange={handlepartchange}  label="Type(Lower or Upper)" >
                                                    <option selected>Select</option>
                                                    <option value='Upper'>Upper</option>
                                                    <option value='Lower'>Lower</option>
                                                </AvField>
                                            </Col>
                                            <Col md={3}>
                                            <AvField type="select" name="article_part_name" value={selectedpartname}
                                            onChange={handlepartname} label="Article Part Name">
                                                <option selected>Select</option>
                                                {articlepartname.map((type, index) => ( 
                                                    <option key={index} value={type}>{type}</option>
                                                    ))}
                                            </AvField>

                                            </Col>
                                            </Row>
                                            <Row>
                                                <hr />
                                                
                                            </Row>
                                            <Row>  
                                            {show &&!loading && elementData && elementData.length > 0 && elementData.map((el, index) => (
                                          <React.Fragment key={index}>
                                            {el.element && el.element.length > 0 && el.element.map((innerEl, innerIndex) => (
                                              <React.Fragment key={innerIndex}>
                                                <Col md={3}>
                                                  <AvField
                                                    name={`measurevalue${index}${innerIndex}`}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="In Inch"
                                                    label={innerEl}
                                                  />
                                                </Col>
                                              </React.Fragment>
                                            ))}
                                          </React.Fragment>
                                        ))}                                                                                                                                                          
                                            
                                    {loading &&
                                            Array.from(measureValuesMap.values()).map((measure, index) => (
                                              <React.Fragment key={index}>
                                                <Col md={3}>
                                                  <AvField
                                                    name={`common_measurevalue${index}`}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="In Inch"
                                                    label={measure.measurename}
                                                    value={measure.measurevalue}
                                                    onChange={(e) => handleMeasureupdateChange(e, measure.measurename)}
                                                  />
                                                </Col>
                                              </React.Fragment>))}                                                                            
                                           </Row>
                                           <Row> <Col md={4}></Col>
                                           <Col md={4}>
                                               {show&& !loading &&
                                                <AvField
                                                    label="Remarks"
                                                    name="remarks"
                                                    type="textarea"
                                                    value={remarks}
                                                    onChange={handleremarks}
                                                    className="form-control"
                                                    placeholder="Remark"
                                                    validate={{ required: { value: true } }} 
                                                />}
                                                 {loading && checkdata[0] && <AvField
                                                    label="Remarks"
                                                    name="remarks"
                                                    type="textarea"
                                                    value={checkdata[0].remarks }
                                                    onChange={updateremarks}
                                                    className="form-control"
                                                    placeholder="Remark"
                                                    validate={{ required: { value: true } }} 
                                                />} 

                                            </Col>
                                           </Row>
                                        <Row>
                                            <Col lg={12}>
                                                <FormGroup className="mb-0 text-center mt-5">
                                                    <div>
                                                        <Button
                                                        id="myBtn"
                                                            type="submit"
                                                            color="primary"
                                                            className="mr-1"
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                    </AvForm>
                                </CardBody>
                                <div className="card-footer d-none border-top-0">
                                </div>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={12}>
                            <Card className=" custom-card">
                                <CardHeader className="justify-content-between">
                                    <CardTitle>

                                    </CardTitle>
                                    <br />
                                    <div className="d-flex flex-wrap gap-2">
                                        <div>
                                            <input
                                                className="form-control form-control-sm"
                                                type="text"
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                placeholder="Search Here"
                                                aria-label=".form-control-sm example"
                                            />
                                        </div>
                                        <div className="dropdown">
                                            <a
                                              
                                                className="btn btn-primary btn-sm btn-wave waves-effect waves-light"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Sort By
                                                <i className="ri-arrow-down-s-line align-middle ms-1 d-inline-block" />
                                            </a>
                                            <ul className="dropdown-menu" role="menu">
                                                <li>
                                                    <a className="dropdown-item"   onClick={() => handleFilter('New')}>
                                                        New
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item"  onClick={() => handleFilter('sortbyname')}>
                                                        Sort by name
                                                    </a>
                                                </li>
                                               
                                            </ul>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive">
                                        <table className="table text-nowrap table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Customers Name</th>     
                                                    <th scope="col">Phone</th>                                                   
                                                    <th scope="col">Article Name</th>
                                                    <th scope="col">Type</th>
                                                    <th scope="col">Part Name</th>
                                                    <th scope="col">Elements Measurement</th>
                                                    <th scope="col">Remark</th>
                                                    <th scope="col">Action</th>
                                                    <th scope="col">Admin</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                    {filteredmapmeasurement.map((data,index)=>(
                                            <tr key={data._id}>
                                                <th>{index+1}</th>
                                                <th scope="row">{data.customername}</th>
                                                <td>{data.phone}</td>
                                                <td>{data.articlename}</td>
                                                <td>{data.type}</td>
                                                <td>{data.partname}</td>
                                                    <td>                                                              
                                                   <Row xs="3">                                                     
                                                  {data.measure.map((measur, measureIndex) => (
                                                   
                                                     <Col className="table-responsive"  key={measureIndex}> 
                                                      <table className="table text-nowrap table-bordered secontTAble">
                                                     <thead> <tr ><th >{measur.measurename}
                                                                  </th></tr></thead>                
                                                     <tbody> <tr><td>{measur.measurevalue}</td>
                                                             </tr> </tbody>                                                      
                                                      </table>
                                                      </Col>    ))}                                                
                                                   </Row>                                                     
                                                      </td>
                                                     <td style={{ whiteSpace: 'pre-line' }}>{data.remarks}</td>
                                                    <td>
                                                        <div className="hstack gap-2 flex-wrap">
                                                            <a href="javascript:void(0);" onClick={() => { onOpen(); measurefetchbyid(data._id);}}
                                                            
                                                            class="btn btn-icon btn-sm btn-info-transparent rounded-pill"><i
                                                            class="ri-edit-line"></i></a>
                                                            <a href="javascript:void(0);"  onClick={() => deletedata(data._id)}  class="btn btn-icon btn-sm btn-danger-transparent rounded-pill"><i
                                                                            class="ri-delete-bin-line"></i></a>
                                                        </div>
                                                    </td>
                                                    <td>{data.admin}</td>
                                              </tr>))}
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal isOpen={isOpen} size="xl" onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Update Element Measurement</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                       
                    {updatedMeasureData?(   
                         <AvForm>
                            <Row className='accordion_css'>
                                <Col md={3}>
                                    <AvField 
                                    type="text"
                                    name="customername"
                                    value={updatedMeasureData.customername } 
                                    defaultValue={updatedMeasureData.customername}
                                    onChange={(e) => handleDataChange(e, "customername")} 
                                    label="Customer Name"
                                    readOnly={true}/>
                                      
                                        
                                
                                </Col>
                                <Col md={3}>
                                <AvField 
                                 type="text"
                                 defaultValue= { updatedMeasureData.articlename}
                                 value={updatedMeasureData.articlename } 
                                 name="article_name" 
                                 label="Article Name"
                                 readOnly={true}/>                                
                                                           
                                </Col>
                                <Col md={3}>
                                    <AvField
                                     type="text" 
                                     name="parts"
                                     label="Type(Lower or Upper)"
                                     defaultValue={updatedMeasureData.type}
                                     value={updatedMeasureData.type}
                                     readOnly={true} />
                                </Col>
                                <Col md={3}>
                                <AvField 
                                type="text" 
                                name="article_part_name"
                                value={updatedMeasureData.partname}
                                defaultValue={updatedMeasureData.partname}
                                label="Article Part Name" 
                                readOnly={true}/>
                                                
                                  </Col>
                                <Row>
                                    <hr />
                                    <br />
                                </Row>
                                
                                  {updatedMeasureData && updatedMeasureData.measure.map((data,index)=>(
                                    <React.Fragment key={index}>
                                       
                                        <Col md={3}>
                                        <AvField
                                        type="text"
                                        label={data.measurename}
                                        name={data._id}
                                        value={data.measurevalue}
                                        onChange={(e) => handleMeasureChange(e, data._id)}
                                        />
                                        </Col>
                                    </React.Fragment>  
                                ))}                                          
                               <Row>
                                <Col md={2}>
                                    <p className='mt-2'> Remarks </p>
                                </Col>
                                <Col md={10}>
                                    <AvField
                                        name="remarks"
                                        type="textarea"
                                        value={updatedMeasureData.remarks}
                                        onChange={(e) => handleDataChange(e, "remarks")}
                                        className="form-control"
                                        placeholder="Remark"
                                        multiline
                                    />
                                </Col></Row>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <FormGroup className="mb-0 text-center mt-3">
                                        <div>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="mr-1" onClick={() => { onClose(); onupdateform(updatedMeasureData._id); }}
                                            >
                                                Update <i class="fa fa-pencil-square-o" aria-hidden="true"></i>

                                            </Button>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </AvForm>):( <div class="spinner-border text-info" role="status">
  <span class="visually-hidden">Loading...</span>
</div>)}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default AddElemeasure
