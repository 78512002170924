import React from 'react'
import Aside from '../Components/Aside'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Modal from '../Components/Modal'
import PaymentsDetails from '../Components/PageComponents/PaymentsDetails'

const Payments = () => {
    return (
        <>
            <Header />
            <Aside />
            <div className="page">
            <PaymentsDetails />
            </div>
            <Modal />
            <Footer />

        </>
    )
}

export default Payments
