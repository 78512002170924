import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
    Row,
    Col,
    CardBody,
    Card,
    
    Container,
   
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import Swal from 'sweetalert2'
//import { log } from 'console'

const Login = () => {
    const navigate = useNavigate();
   
    // const loginuser = () => {
    //     localStorage.setItem('login', true)
    //     navigate('/Dashboard')
    // }
    // const navigate = useNavigate();
    // useEffect(() => {
    //     let login = localStorage.setItem('login', true);
    //     if (login) {
    //         navigate('/Dashboard')
    //     }
    // });
 //  console.log(process.env.REACT_APP_BASE_URL,"Dfvxvxvz");
    const loginuser = async(e,v) => {
        e.preventDefault();
       // navigate('/Dashboard');
        try {
            let payload = {
                username: v.username,                
                password:v.password,
            }
           
            const api_url = process.env.REACT_APP_BASE_URL;
            console.log(payload, "PL", api_url);
            const response = await fetch(`${api_url}/adminuser/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
           
            // const results = await response.json();
            // if (results.status === 1) {
            //   const token = results.data.token;
            //   localStorage.setItem('authToken', token);
            //   localStorage.setItem('username',payload.username)
            //   navigate('/Dashboard');

            // }
            
              
                const result  = await response.json();
                if (result.status === 1) {   
                     const  token  = result.data.token;
                     localStorage.setItem('authToken', token);                
                     localStorage.setItem('username',payload.username)                
                     navigate('/Dashboard');
                    const Toast = Swal.mixin({
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                          toast.onmouseenter = Swal.stopTimer;
                          toast.onmouseleave = Swal.resumeTimer;
                        }
                      });
                      Toast.fire({
                        icon: "success",
                        title: "Signed in successfully"
                      });
                    
            } else {
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.onmouseenter = Swal.stopTimer;
                      toast.onmouseleave = Swal.resumeTimer;
                    }
                  });
                Toast.fire({
                    icon: "error",
                    title: "Signed failed"
                  });
               
                console.error('Failed to login ');
            }          
        }catch (error) {
            console.error('Error login:', error);
        }
    };
  
    return (
        <>
            <div className="pages login_page__bg">
                <div className="login_bg_layer" >
                    <Container className="container">
                        <Row className=" justify-content-center align-items-center authentication authentication-basic h-100">
                            <Col xxl={4} xl={5} lg={5} md={6} sm={8}>
                                <div className="mb-5 d-flex justify-content-center">
                                    <Link to="/Dashboard">
                                        <h1 className="logoheading">Yunus Tailors</h1>
                                        {/* <img
                                            src="assets/images/logo.png"
                                            alt="logo"
                                            className="desktop-logo"
                                        /> */}
                                    </Link>
                                </div>
                                <Card className=" custom-card">
                                    <CardBody className="pt-4 p-5">
                                        <p className="h4 fw-semibold mb-4 text-center">Sign In</p>
                                        <AvForm  onValidSubmit={loginuser}>
                                            <Row className=" gy-3">
                                                <Col xl={12}>
                                                    <AvField
                                                        name="username"
                                                        type="text"  
                                                                                                       
                                                        className="form-control form-control-lg"
                                                        placeholder="user name"
                                                        label="Username"
                                                        validate={{ required: { value: true } }}
                                                        errorMessage={`Username cannot be empty`}

                                                    />
                                                </Col>
                                                <Col xl={12} mb={2}>
                                                    <AvField
                                                        name="password"
                                                        type="password" 
                                                        aria-describedby="passwordHelpInline"                                                                                                           
                                                        className="form-control form-control-lg"
                                                        placeholder="Password"
                                                        label="Password"
                                                        validate={{ required: { value: true } }}
                                                        errorMessage={`Password cannot be empty`}

                                                    />
                                                </Col>

                                             
                                                <div className="col-xl-12 d-grid mt-2">
                                                    <button type="submit"  className="btn btn-lg btn-primary">
                                                        Sign In
                                                    </button>
                                                  
                                                </div>
                                            </Row>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default Login
