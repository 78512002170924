import React, { useState ,useEffect} from 'react'
import Home from '../Pages/Home';
import Articals from '../Pages/Articals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from '../Pages/Login';
import Protected from '../Components/Protected';
import Element from '../Pages/Element';
import AddElement from '../Pages/AddElement';
import ElementMapping from '../Pages/ElementMapping';
import AddCustomers from '../Customers/AddCustomers';
import Allcustomers from '../Customers/Allcustomers';
import Orders from '../Pages/Orders';
import Contact from '../Pages/Contact';
import ChangePassword from '../Pages/ChangePassword';
import Payments from '../Pages/Payments';
import AddOrders from '../Customers/AddOrder';
import AddPayments from '../Customers/AddPayments';
import InvoicePrint from '../Pages/InvoicePrint';
const AllRoutes = () => {
    const [isLogged, setIsLogged] = useState();
    const checkUserToken = () => {
        const token = localStorage.getItem('authToken');
        if (token) {
            setIsLogged(true);
        } else {
            setIsLogged(false);
            
        }
    }
    useEffect(() => {
        checkUserToken();
    }, [isLogged]);



    return (
        <BrowserRouter>
            {/* <Header />
            <Aside /> */}
            {/* <Protected component={<Header />}></Protected>
            <Protected component={<Aside />}></Protected> */}


            <Routes>
                <Route element={<Protected />}>
                    <Route path='/Dashboard' element={<Home />} />
                    <Route path='/Articles' element={<Articals />} />
                    <Route path='/Element' element={<Element />} />
                    <Route path='/AddElement' element={<AddElement />} />
                    <Route path='/ElementMapping' element={<ElementMapping />} />
                    <Route path='/AllCustomers' element={<Allcustomers />} />
                    <Route path='/AddCustomer' element={<AddCustomers />} />
                    <Route path='/Orders' element={<Orders />} />
                    <Route path='/AddOrder' element={<AddOrders />} />
                    <Route path='/Contact' element={<Contact />} />
                    <Route path='/ChangePassword' element={<ChangePassword />} />
                    <Route path='/payments' element={<Payments />} />
                    <Route path='/AddPayment/:id' element={<AddPayments/>}/>                  
                    <Route path='/InvoicePrint/:id' element={<InvoicePrint/>} />
                   

                </Route>
                <Route path='/' element={<Login />} />

                {/* <Route path='/Dashboard' element={
                    <Protected component={<Home />}></Protected>
                } />
                <Route path='/Articles' element={
                    <Protected component={<Articals />}></Protected>
                } />
                <Route path='/Element' element={
                    <Protected component={<Element />}></Protected>
                } />
                <Route path='/AddElement' element={
                    <Protected component={<AddElement />}></Protected>
                } />
                <Route path='/ElementMapping' element={
                    <Protected component={<ElementMapping />}></Protected>
                } /> */}
                {/* <Route path='/AllCustomers' element={
                    <Protected component={<Allcustomers />}></Protected>
                } /> */}
                {/* <Route path='/AddCustomer' element={
                    <Protected component={<AddCustomers />}></Protected>
                } /> */}
                {/* <Route path='/Orders' element={
                    <Protected component={<Orders />}></Protected>
                } />
                <Route path='/Reports' element={
                    <Protected component={<Reports />}></Protected>
                } />
                <Route path='/Contact' element={
                    <Protected component={<Contact />}></Protected>
                } />
                <Route path='/ChangePassword' element={
                    <Protected component={<ChangePassword />}></Protected>
                } /> */}
            </Routes>
            {/* <Protected component={<Footer />}></Protected> */}
        </BrowserRouter>
    )
}

export default AllRoutes
