import React,{useState,useEffect,useRef} from 'react'
import { Row, Col, Card, CardHeader, CardBody, FormGroup, Button, CardTitle } from "reactstrap"
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,

    ModalBody,
    ModalCloseButton,
    useDisclosure,
} from '@chakra-ui/react'
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import Chart from 'chart.js/auto';
import Swal from 'sweetalert2'

const StaticPieChart = ({ totalOrderAmount, totalPaidAmount }) => {
    const chartRef = useRef(null);
  
    useEffect(() => {
      const data = {
        labels: ['Paid Amount', 'Due Amount'],
        datasets: [{
          label: 'Amount',
          data: [ totalPaidAmount, totalOrderAmount - totalPaidAmount],
          backgroundColor: [
            'rgba(255, 165, 5, 1)',   
            'rgba(38, 191, 148, 1)'  
          ],
          hoverOffset: 2
        }]
      };  
      const options = {
        responsive: true,
        maintainAspectRatio: true
      };
      const myChart = new Chart(chartRef.current, {
        type: 'pie',
        data: data,
        options: options
      });
      return () => myChart.destroy();
    }, [totalOrderAmount, totalPaidAmount]);
    
  
    return <canvas ref={chartRef} />;
  }
const HomeContent = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const api_url = process.env.REACT_APP_BASE_URL;
    const [searchTerm, setSearchTerm] = useState('');
    const currentDate = new Date();
    const navigate = useNavigate();

    const [maparticles, setArticlesmap] = useState([]);
    const [OrderData, setOrderData] = useState([]);
    const [OrderDatalm, setOrderDatalm] = useState([]);
    const [Data, setData] = useState([]);
    const [Delivery_status,setDelivery_status]=useState();
    const getInitials = (admin1) => {
        return admin1 ? admin1.charAt(0).toUpperCase() : '';
      };
    const handledeliveryStatuschange = async (event, orderId) => {
        console.log(event.target.value, orderId, "before");
        const d_status = event.target.checked; // Use event.target.checked to get the status of the checkbox
        console.log(d_status, orderId, "after");
        try {
            const result = await Swal.fire({
                title: "Changing Delivery Status",
                text: "You  can chang delivery status!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm Delivery Status!"
              });          
              if (result.isConfirmed) {
            const response = await fetch(`${api_url}/updatedeliverystatus/${orderId}/${d_status}`,
                {
                    method: "PUT",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                });
            if (response.ok) {
                Swal.fire({
                    title: "Updated!",
                    text: "Your Delivery Status has been Updated.",
                    icon: "success"
                  });
            } else {
                console.error("Failed to update delivery status.");
                Swal.fire({
                    title: "Error!",
                    text: "Failed to Update the status.",
                    icon: "error"
                  });
            }
        }} catch (error) {
            Swal.fire({
                title: "Error!",
                text: "An unexpected error occurred.",
                icon: "error"
              });
            console.error("Error updating delivery status:", error.message);
            // Handle error if needed
        }
    };
useEffect(() => {
const fetchmap = async () => {
        try {
          const response = await fetch(`${api_url}/addcustomer`);
          if (!response.ok) {
            throw new Error('Failed to fetch articles');
          }
          const data = await response.json();
          setArticlesmap(data);    
        } catch (error) {
          console.log(error.message);
        }
      };
      const fetchorder = async () => {
        try {
          const response = await fetch(`${api_url}/orderofmonth`);
          const data = await response.json();
          setOrderData(data);
          console.log(data);
        } catch (error) {
          console.log(error.message);
        }
      };    
      const fetchorderoflastmonth = async () => {
        try {
          const response = await fetch(`${api_url}/orderoflastmonth`);
          const data = await response.json();
          setOrderDatalm(data);
          console.log(data);
        } catch (error) {
          console.log(error.message);
        }
      };
      
      fetchorder();
      fetchmap();
      fetchorderoflastmonth();
    }, [api_url]);
    // Assuming data is an array of objects with the structure you provided
// Assuming OrderData is an array of objects with the structure you provided
const totalOrderAmountoflm = OrderDatalm.reduce((total, order) => total + order.Totalamount, 0);
const totalPaidAmout = OrderData.reduce((total, order) => total + order.paidamount.reduce((acc, val) => acc + val.paid, 0), 0);
const totalOrderAmount = OrderData.reduce((total, order) => total + order.Totalamount, 0);
const dueAmount=totalOrderAmount-totalPaidAmout;
const duepercent=((dueAmount/totalOrderAmount)*100).toFixed(2);
//console.log("Total amount for all orders:", totalOrderAmount,totalPaidAmout);
console.log("Total amount for all orders:", totalOrderAmount);
console.log("Total amount for last orders:", totalOrderAmountoflm);
const percentofchange=(totalOrderAmount-totalOrderAmountoflm)/100;
console.log(percentofchange);

const deletedata= async(id)=>{
        try {
            const result = await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!"
              });
          
              if (result.isConfirmed) {
              const response = await fetch(`${api_url}/addcustomer/${id}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
          });
          console.log(response.status, "PLdelete",response.ok);
          if (response.ok) {
           const updatedData = maparticles.filter((item) => item._id !== id);
           setArticlesmap(updatedData);
           Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success"
          });
          } else {
            Swal.fire({
                title: "Error!",
                text: "Failed to delete the element.",
                icon: "error"
              });
            console.error('Failed to delete article from server');
          }}
        } catch (error) {  Swal.fire({
            title: "Error!",
            text: "An unexpected error occurred.",
            icon: "error"
          });
          console.error('Error deleting article:', error.message);
        }
      };
const filteredArticles = maparticles.filter(a => a.name.toLowerCase().includes(searchTerm.toLowerCase())|| 
                                                 a.phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                                 a.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                                 a.reference.toLowerCase().includes(searchTerm.toLowerCase()));
      
      const [Customerdata ,setCustomerData]=useState([]);
const customerfetchbyid= async(id)=>{
        try {
            const response = await fetch(`${api_url}/addcustomer/${id}`);
            if (response.ok) {
              const data = await response.json();
              setCustomerData(data);
            } else {
              console.error(`Failed to fetch customer data. Status: ${response.status}`);
            }
          } catch (error) {
            console.error('Error fetching customer data:', error.message);
          }
      //  console.log("customerfetchbyid:",Customerdata);
    }
    const [updatedData, setUpdatedData] = useState({});
const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUpdatedData((prevData) => ({
        ...prevData,
        [name]: value,
        }));
    };
const updatedata= async(id)=>{
    try {
        const result = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, update it!"
          });
          if (result.isConfirmed) {
        const response = await fetch(`${api_url}/customername/${id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedData),
        });    
        if (response.ok) {       
          const updatedData = await response.json();
          Swal.fire({
            title: "Updated!",
            text: "Your file has been updated.",
            icon: "success"
          });
          setArticlesmap((pre)=> pre.map((items) => 
          items._id === updatedData._id ? {...items,...updatedData} :items
          ));
        } else { 
             Swal.fire({
            title: "Updated!",
            text: "Your file has been updated.",
            icon: "success"
          });
          console.error(`Failed to update customer data. Status: ${response.status}`);
        }}
      } catch (error) {
        Swal.fire({
            title: "Error!",
            text: "An unexpected error occurred.",
            icon: "error"
          });
        console.error('Error updating customer data:', error.message);
      }
} 

const [filterdata,setfilter]=useState([]);
const handleFilter=(selecteds)=>{
    console.log(selecteds);
    const x= OrderData.filter(
    (item) => item.Payment_status.toLowerCase().includes(selecteds.toLowerCase())
    );
    setfilter(x);
};
const renderedData = filterdata.length > 0 ? filterdata : OrderData;
const [currentPage, setCurrentPage] = useState(1);
const startIndex = (currentPage - 1) * 10;
const endIndex = currentPage * 10;

const nextPage = () => {
  setCurrentPage((prevPage) => prevPage + 1);
};
const prevPage = () => {
  setCurrentPage((prevPage) => prevPage - 1);
};
   return (
        <>
            <div className="main-content app-content">
                <div className="container-fluid">
                    <div className="d-md-flex d-block align-items-center justify-content-between mb-4 page-header-breadcrumb">
                        <div>
                            <p className="fw-semibold fs-18 mb-0">Welcome Tailoring Shop Management System</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12">
                            <div className="row carddesign">
                                <div className="col-xl-12">
                                    <div className="row">
                                        <div className="col-xxl-3 col-lg-3 col-md-3">
                                            <div className="card custom-card overflow-hidden">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-top justify-content-between">
                                                        <div>
                                                            <span className="avatar avatar-md avatar-rounded bg-primary">

                                                                <i className="fa fa-users fs-16" />
                                                            </span>
                                                        </div>
                                                        <div className="flex-fill ms-3">
                                                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                                <div>
                                                                    <p className="text-muted mb-0">Total Customers</p>
                                                                    <h6 className="fw-semibold mt-1">{maparticles.length}</h6>
                                                                </div>
                                                                <div id="crm-total-customers" />
                                                            </div>
                                                            <div className="d-flex align-items-center justify-content-between mt-1">
                                                                <div>
                                                                    <Link
                                                                        className="text-primary"
                                                                        to="/AllCustomers"
                                                                    >
                                                                        View All
                                                                        <i className="ti ti-arrow-narrow-right ms-2 fw-semibold d-inline-block" />
                                                                    </Link>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                       
                                        <div className="col-xxl-3 col-lg-3 col-md-3">
                                            <div className="card custom-card overflow-hidden">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-top justify-content-between">
                                                        <div>
                                                            <span className="avatar avatar-md avatar-rounded bg-primary">

                                                                
                                                                <i className="bx bx-table fs-16" />
                                                            </span>
                                                        </div>
                                                        <div className="flex-fill ms-3">
                                                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                                <div>
                                                                    <p className="text-muted mb-0">Total Order</p>
                                                                    <h6 className="fw-semibold mt-1">{OrderData.length}</h6>
                                                                </div>
                                                                <div id="crm-total-customers" />
                                                            </div>
                                                            <div className="d-flex align-items-center justify-content-between mt-1">
                                                                <div>
                                                                    <Link
                                                                        className="text-primary"
                                                                        to="/Orders"
                                                                    >
                                                                        View All
                                                                        <i className="ti ti-arrow-narrow-right ms-2 fw-semibold d-inline-block" />
                                                                    </Link>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-3 col-lg-3 col-md-3">
                                            <div className="card custom-card overflow-hidden">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-top justify-content-between">
                                                        <div>
                                                            <span className="avatar avatar-md avatar-rounded bg-primary">

                                                                <i className="fa fa-money fs-16" />
                                                            </span>
                                                        </div>
                                                        <div className="flex-fill ms-3">
                                                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                                <div>
                                                                    <p className="text-muted mb-0">Payment Details</p>
                                                                    <h6 className="fw-semibold mt-1">{OrderData.filter(order => order.Payment_status != "Paid").length}</h6>
                                                                </div>
                                                                <div id="crm-total-customers" />
                                                            </div>
                                                            <div className="d-flex align-items-center justify-content-between mt-1">
                                                                <div>
                                                                    <Link
                                                                        className="text-primary"
                                                                        to="/Payments"
                                                                    >
                                                                        View 
                                                                        <i className="ti ti-arrow-narrow-right ms-2 fw-semibold d-inline-block" />
                                                                    </Link>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-3 col-lg-3 col-md-3">
                                            <div className="card custom-card overflow-hidden">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-top justify-content-between">
                                                        <div>
                                                            <span className="avatar avatar-md avatar-rounded bg-primary">

                                                                <i className="fa fa-address-book fs-16" />
                                                            </span>
                                                        </div>
                                                        <div className="flex-fill ms-3">
                                                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                                <div>
                                                                    <p className="text-muted mb-0">Contact</p>
                                                                    <h6 className="fw-semibold mt-1">2</h6>
                                                                </div>
                                                                <div id="crm-total-customers" />
                                                            </div>
                                                            <div className="d-flex align-items-center justify-content-between mt-1">
                                                                <div>
                                                                    <Link
                                                                        className="text-primary"
                                                                        to="/Contact"
                                                                    >
                                                                        View All
                                                                        <i className="ti ti-arrow-narrow-right ms-2 fw-semibold d-inline-block" />
                                                                    </Link>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>  
                                        <div className="col-xxl-3 col-lg-3 col-md-3">
                                            <div className="card custom-card overflow-hidden">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-top justify-content-between">
                                                        <div>
                                                            <span className="avatar avatar-md avatar-rounded bg-warning">

                                                                
                                                                <i className="bx bx-store fs-16" />
                                                            </span>
                                                        </div>
                                                        <div className="flex-fill ms-3">
                                                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                                <div>
                                                                    <p className="text-muted mb-0">Add Order</p>
                                                                    <h6 className="fw-semibold mt-1">{OrderData.length}</h6>
                                                                </div>
                                                                <div id="crm-total-revenue" />
                                                            </div>
                                                            <div className="d-flex align-items-center justify-content-between mt-1">
                                                                <div>
                                                                    <Link
                                                                        className="text-warning"
                                                                        to="/AddOrder"
                                                                    >
                                                                        Add
                                                                        <i className="ti ti-arrow-narrow-right ms-2 fw-semibold d-inline-block" />
                                                                    </Link>
                                                                </div>
                                                                <div className="text-end ">
                                                                    <p className="mb-0   op-0 fw-semibold">
                                                                        +25%
                                                                    </p>
                                                                    <span className="text-muted op-0 fs-11">
                                                                       ,
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-3 col-lg-3 col-md-3">
                                            <div className="card custom-card overflow-hidden">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-top justify-content-between">
                                                        <div>
                                                            <span className="avatar avatar-md avatar-rounded bg-warning">

                                                                
                                                                <i className="fa fa-user fs-16" />
                                                            </span>
                                                        </div>
                                                        <div className="flex-fill ms-3">
                                                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                                <div>
                                                                    <p className="text-muted mb-0">Add Customer</p>
                                                                    <h6 className="fw-semibold mt-1">{maparticles.length}</h6>
                                                                </div>
                                                                <div id="crm-total-revenue" />
                                                            </div>
                                                            <div className="d-flex align-items-center justify-content-between mt-1">
                                                                <div>
                                                                    <Link
                                                                        className="text-warning"
                                                                        to="/AddCustomer"
                                                                    >
                                                                        Add
                                                                        <i className="ti ti-arrow-narrow-right ms-2 fw-semibold d-inline-block" />
                                                                    </Link>
                                                                </div>
                                                                <div className="text-end">
                                                                    <p className="mb-0  op-0  fw-semibold">
                                                                       +
                                                                    </p>
                                                                    <span className="text-muted op-0 fs-11">
                                                                        this month
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                        
                                         <div className="col-xxl-3 col-lg-3 col-md-3">
                                            <div className="card custom-card overflow-hidden">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-top justify-content-between">
                                                        <div>
                                                            <span className="avatar avatar-md avatar-rounded bg-success">

                                                                
                                                                <i className="fa fa-inr fs-16" />
                                                            </span>
                                                        </div>
                                                        <div className="flex-fill ms-3">
                                                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                                <div>
                                                                    <p className="text-muted mb-0">Due Amount</p>
                                                                    <h6 className="fw-semibold mt-1" >  <i className="fa fa-inr" />{dueAmount}</h6>
                                                                </div>
                                                                <div id="crm-total-revenue" />
                                                            </div>
                                                            <div className="d-flex align-items-center justify-content-between mt-1">
                                                                <div>
                                                                    <Link
                                                                        className="text-success"
                                                                        to="/Payments"
                                                                    >
                                                                        View
                                                                        <i className="ti ti-arrow-narrow-right " />
                                                                    </Link>
                                                                </div>
                                                                <div className="text-end">
                                                                    <p className="mb-0 text-danger fw-semibold">
                                                                        {duepercent}%
                                                                    </p>
                                                                    <span className="text-muted op-7 fs-9">
                                                                        this month
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-3 col-lg-3 col-md-3">
                                            <div className="card custom-card overflow-hidden">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-top justify-content-between">
                                                        <div>
                                                            <span className="avatar avatar-md avatar-rounded bg-success">

                                                                
                                                                <i className="fa fa-calculator  fs-16" />
                                                            </span>
                                                        </div>
                                                        <div className="flex-fill ms-3">
                                                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                                <div>
                                                                    <p className="text-muted mb-0">Total Amount</p>
                                                                    <h6 className="fw-semibold mt-1" >  <i className="fa fa-inr" />{totalOrderAmount}</h6>
                                                                </div>
                                                                <div id="crm-total-revenue" />
                                                            </div>
                                                            <div className="d-flex align-items-center justify-content-between mt-1">
                                                                <div>
                                                                    <Link
                                                                        className="text-success"
                                                                        to="/Payments"
                                                                    >
                                                                        View
                                                                        <i className="ti ti-arrow-narrow-right ms-2 fw-semibold d-inline-block" />
                                                                    </Link>
                                                                </div>
                                                                <div className="text-end">
                                                            <p className={`mb-0 ${percentofchange > 0 ? 'text-success' : 'text-danger'} fw-semibold`}>
                                                                {percentofchange}%
                                                            </p>
                                                            <span className="text-muted op-7 fs-9">
                                                                this month
                                                            </span>
                                                        </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                                <Row>
                                
                        <Col md={9}> 
                            <Card className=" custom-card">
                            <CardHeader className="justify-content-between">
                                    <CardTitle> All  Customers <br />
                                        <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            placeholder="Search Here"
                                            aria-label=".form-control-sm example"
                                        />
                                    </CardTitle>
                                    </CardHeader>
                                <CardBody>
                                    <div className="table-responsive">
                                    {maparticles ? (
    <div>
        <table className="table text-nowrap table-bordered">
            <thead>
                <tr>
                    {/* <th scope="col">No.</th> */}
                    <th scope="col">Name</th>                                                  
                    <th scope="col">Phone</th>
                    <th scope="col">Email</th>
                    <th scope="col">Reference</th>                                                   
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                {filteredArticles.slice(startIndex, endIndex).map((a, index) => (
                    <tr key={a._id}>
                        {/* <th>{index+1}</th> */}
                        <th scope="row">{a.name}</th>                                                   
                        <td>{a.phone}</td>
                        <td>{a.email}</td>
                        <td>{a.reference}</td>                                                   
                        <td>
                            <div className="hstack gap-2 flex-wrap">
                                <a onClick={() => { onOpen(); customerfetchbyid(a._id); }} 
                                    className="btn btn-icon btn-sm btn-info-transparent rounded-pill">
                                    <i className="ri-edit-line"></i>
                                </a>
                                <a onClick={() => deletedata(a._id)}
                                    className="btn btn-icon btn-sm btn-danger-transparent rounded-pill">
                                    <i className="ri-delete-bin-line"></i>
                                </a>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        <div className="pagination justify-content-end mb-0 mt-4">
            <button
                className="btn btn-outline-secondary mr-2"
                onClick={prevPage}
                disabled={currentPage === 1}
            >
                Previous
            </button>
            <button
                className="btn btn-outline-secondary ml-2"
                onClick={nextPage}
                disabled={endIndex >= filteredArticles.length}
            >
                Next
            </button>
        </div>
    </div>
) : (
    <div className="spinner-border text-info" role="status">
        <span className="visually-hidden">Loading...</span>
    </div>
)}
                 
                                    </div>
                                    
                                </CardBody>
                            </Card>                                         
                        </Col>
                        <Col md={3}>
                        <Card className=" custom-card">
                            <CardHeader className="justify-content-between">
                                    <CardTitle> Total Amount <br/>
                                        {/* :<i className="fa fa-inr" />{totalOrderAmount} <br /> */}
                                    <br />
                                    </CardTitle>
                                    </CardHeader>
                                <CardBody>
                                
                                    <StaticPieChart totalOrderAmount={totalOrderAmount} totalPaidAmount={totalPaidAmout} />
                                   
                        </CardBody>
                        <hr/>
                       
                        <div class="row row-cols-12 justify-content-center">
                                    <div class="col pe-0 text-center">
                                        <div class="p-sm-3 p-2 ">
                                        <span class="text-muted fs-11">
                                            Paid Amount
                                        </span>
                                        <span class="d-block fs-16 fw-semibold"><i className="fa fa-inr" />
                                           {totalPaidAmout}
                                        </span>
                                        </div>
                                    </div>
                                    <div class="col px-0 text-center">
                                        <div class="p-sm-3 p-2 ">
                                        <span class="text-muted fs-11">
                                            Due Amount
                                        </span>
                                        <span class="d-block fs-16 fw-semibold"><i className="fa fa-inr" />
                                            {dueAmount}
                                        </span>
                                        </div>
                                    </div>
                                    </div>
                        </Card>
                       

                            </Col>       
                    </Row>
                            </div>
                        </div>

                    </div>
                    {/* End::row-1 */}
                </div>
            </div>
            <Modal isOpen={isOpen} size="lg" onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Update Customer Details</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                    {Customerdata ? (      <AvForm>
                           <Row className='accordion_css'>
                                <Col md={3}>
                                    <p> Name:</p>
                                </Col>
                                <Col md={9}>
                                    <AvField
                                        name="name"
                                        type="text"
                                        onChange={handleInputChange}
                                        value={Customerdata.name}
                                        validate={{ required: { value: true } }}
                                        className="form-control"
                                        placeholder="Enter  Name"
                                    />
                                </Col>
                                <Col md={3}>
                                    <p>Phone:</p>
                                </Col>
                                <Col md={9}>
                                <AvField
                                        name="phone"
                                        type="text"                                      
                                        pattern="[0-9]*"
                                        maxLength="10"
                                        validate={{
                                            required: { value: true, errorMessage: 'Phone number is required' },
                                            maxLength: { value: 10, errorMessage: 'Phone number must be 10 digits' },
                                            pattern: { value: /^[0-9]*$/, errorMessage: 'Please enter a valid phone number' }
                                        }}
                                        value={Customerdata.phone}
                                        onChange={handleInputChange}
                                        className="form-control"
                                        placeholder="Enter Phone"
                                    />
                                </Col>
                                <Col md={3}>
                                    <p>Email:</p>
                                </Col>
                                <Col md={9}>
                                    <AvField
                                        name="email"
                                        type="text"
                                        value={Customerdata.email}
                                        onChange={handleInputChange}
                                        validate={{ required: { value: true } }}
                                        className="form-control"
                                        placeholder="Enter Email"
                                    />
                                </Col>
                                <Col md={3}>
                                    <p>Reference:</p>
                                </Col>
                                <Col md={9}>
                                    <AvField
                                        name="reference"
                                        type="text"
                                        value={Customerdata.reference}
                                        onChange={handleInputChange}
                                        validate={{ required: { value: true } }}
                                        className="form-control"
                                        placeholder="Enter Reference"
                                    />
                                </Col>
                                <Col md={3}>
                                    <p style={{ fontSize: "13.7px" }}>Address Line 1:</p>
                                </Col>
                                <Col md={9}>
                                    <AvField
                                        className="form-control"
                                        type="text"
                                        value={Customerdata.address1}
                                        onChange={handleInputChange}
                                        placeholder="Enter Address Line 1"
                                        name="address1"
                                        validate={{ required: { value: true } }}
                                        errorMessage="Address cannot be empty"
                                    />
                                </Col>
                                <Col md={3}>
                                    <p style={{ fontSize: "13px" }}>Address Line 2:</p>
                                </Col>
                                <Col md={9}>
                                    <AvField
                                        className="form-control"
                                        type="text"
                                        value={Customerdata.address2}
                                        onChange={handleInputChange}
                                        placeholder="Enter Address Line 2"
                                        name="address2"
                                        validate={{ required: { value: true } }}
                                        errorMessage="Address cannot be empty"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <FormGroup className="mb-0 text-center mt-3 ">
                                        <Button variant='ghost'  onClick={() => { onClose(); updatedata(Customerdata._id); }}>Update <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </AvForm> ):(<div class="spinner-border text-info" role="status">
  <span class="visually-hidden">Loading...</span>
</div>)}
                    </ModalBody>
                </ModalContent>
            </Modal>
        
        </>
    )
}

export default HomeContent
