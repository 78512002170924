import React from 'react'

const PageHeader = (props) => {
    return (
        <>
            <div className="d-md-flex d-block align-items-center justify-content-between mb-4 page-header-breadcrumb">
                <h1 className="page-title fw-semibold fs-18 mb-0"> {props.title}</h1>
                <div className="ms-md-1 ms-0">
                    <nav>
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item">
                                <a href="/Dashboard">Dashboard</a>
                            </li>
                            <li>&nbsp; <i class="fa fa-angle-double-right" aria-hidden="true"></i></li>
                            <li className="breadcrumb-item active" aria-current="page">
                                    &nbsp;{props.title}
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>

        </>
    )
}

export default PageHeader
