import React from 'react'
import Aside from '../Components/Aside'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Modal from '../Components/Modal'

import Addpayment from '../Components/PageComponents/Addpayment'
const AddPayments = () => {
    return (
        <>
            <Header />
            <Aside />
            <div className="page">
                <Addpayment />
                <Modal />
            </div>
            <Footer />

        </>
    )
}

export default AddPayments
