import React, { useState,useRef ,useEffect} from 'react'
import PageHeader from '../PageHeader'
import { Row, Col, Card, CardHeader, CardBody, FormGroup, Button, CardTitle, Container } from "reactstrap"
import { AvForm, AvField, AvRadio, AvRadioGroup } from "availity-reactstrap-validation"
//import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure
} from '@chakra-ui/react'
const ArticalContent = () => {
    const refContainer = useRef(null)
    const { isOpen, onOpen, onClose } = useDisclosure();
    const api_url = process.env.REACT_APP_BASE_URL;
    const [articleName, setArticlename] = useState('');
    const [unitinput, setUnitinput] = useState(0);
    const [selectedValue, setSelectedValue] = useState('');
    const [Data,setData]=useState();
    const [isSubmitDisabled ,setisSubmitDisabled ]=useState(false);
   
    
    const handleSelectChange = (event) => {
        setSelectedValue(event.target.value);
    };
    const handleunitchange = (event) => {
        setUnitinput(event.target.value);
    };
    const submitForm = async (e, v) => {
        e.preventDefault();
        if(selectedValue === "yes"){
           try {
                let payload = {
                    gender: v.gender,
                    articlename: v.articlename,
                    articleunit: unitinput+1,
                    articleparttype:[{articlepart: v.articlename,articletype:v.articletype}]
                }
                console.log(payload, "PL");    
                const response = await fetch( `${api_url}/article`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });                  
                if (response.ok) {
                    Swal.fire({
                        title: "Success!",
                        text: "Article add succeessfully!",
                        icon: "success"
                      });
                    const responseData = await response.json();
                    fetchdataorder();
                    setData((presdata)=> presdata.map((data) => 
                    data._id === responseData._id ? {...data,...responseData} :data));
                    console.log('Response Body:', responseData);
                    console.log(response.statusText, 'successfully');
                    refContainer.current.reset()
                    //setSelectedValue();
              } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                      });
                    console.error('Failed to submit form');
                }
            } catch (error) {
                console.error('Error submitting form:', error);
                
            }           
        }
        else if(selectedValue === "no"){
          
                try {
                    let payload = {
                        gender: v.gender,
                        articlename: v.articlename,
                        articleunit: unitinput,
                        articleparttype: []
                    };
                
                    for (let i = 0; i < unitinput; i++) {
                        payload.articleparttype.push({
                            articlepart: v[`part${i + 1}`],
                            articletype: v[`uplopart${i + 1}`]
                        });
                    }
                    
                    
                    console.log(payload, "PL");        
                    const response = await fetch (`${api_url}/article`, {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(payload),
                    });
                    if (response.ok) {
                        Swal.fire({
                            title: "Success!",
                            text: "Article add succeessfully!",
                            icon: "success"
                          });
                        const responseData = await response.json();
                        fetchdataorder();
                        setData((presdata)=> presdata.map((data) => 
                        data._id === responseData._id ? {...data,...responseData} :data));
                        console.log('Response Body:', responseData);
                        console.log(response.statusText, ' successfully');
                        setUnitinput(0);                    
                        refContainer.current.reset()
                        setSelectedValue('');
                       

                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                          });
                        console.error('Failed to submit form');
                    }
                } catch (error) {
                    console.error('Error submitting form:', error);
                }}         
          
    }
    const fetchdataorder = async () => {
        try {
          const response = await fetch(`${api_url}/articles`);
          const data = await response.json();
          setData(data);
          console.log(data);
        } catch (error) {
          console.log(error.message);
        }
      };
      useEffect(() => {
        fetchdataorder();
      },[]);
      const deletedata= async(id)=>{
        try {
            const result = await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!"
              });          
              if (result.isConfirmed) {
              const response = await fetch( `${api_url}/articledata/${id}`, {
                    method: 'DELETE',
                    headers: {
                      'Content-Type': 'application/json',
            },
          });
          console.log(response.status, "PLdelete",response.ok);
          if (response.ok) {
           const updatedData = Data.filter((item) => item._id !== id);
           setData(updatedData);
           Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success"
          });
          } else {
            Swal.fire({
                title: "Error!",
                text: "Failed to delete the element.",
                icon: "error"
              });
            console.error('Failed to delete article from server');
          }}
        } catch (error) { 
           Swal.fire({
            title: "Error!",
            text: "An unexpected error occurred.",
            icon: "error"
          });
          console.error('Error deleting article:', error.message);
        }
      };
      const[upData,setUpData]=useState();
      const measurefetchbyid= async(id)=>{
        try {
            const response = await fetch(`${api_url}/articledata/${id}`);
            if (response.ok) {
              const data = await response.json();
              setUpData(data);
            
            } else {
              console.error(`Failed to fetch customer data. Status: ${response.status}`);
            }
          } catch (error) {
            console.error('Error fetching customer data:', error.message);
          }    
          console.log(upData);
        
      };
      const handleMeasureChange = (e, id) => {
        const { name, value } = e.target;
        // Update the state or perform any other action based on the changed value
        // For example, update the state with the new value
        setUpData(prevData => ({
            ...prevData,
            articleparttype: prevData.articleparttype.map(item =>
                item._id === id ? { ...item, articlepart: value } : item
            )
        }));
    };
   
    const handleRadioChange = (id, valu) => {
        const { name, value } = valu.target;
        // Update the state or perform any other action based on the selected radio value
        // For example, update the state with the selected radio value
        setUpData(prevData => ({
            ...prevData,
            articleparttype: prevData.articleparttype.map(item =>
                item._id === id ? { ...item, articletype: value } : item
            )
        }));
    };
      const updatedata= async(id,v,e)=>{
        try {
            const result = await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, update it!"
              });
              if (result.isConfirmed) {
                // let payload={
                //     gender:upData.gender,
                //     articlename:upData.articlename,
                //     articleunit:upData.articleunit,
                //     articleparttype:[]
                // };
                // upData.forEach((data,index)=>{
                //     upData.articleparttype.forEach((subdata,subindex)=>{
                //         payload.articleparttype.push({
                //             articlepart: data.articlepart,
                //             articletype: data.articletype,
                //         });
                //     });
                // });
//console.log(payload,"pppl");
console.log(upData,"uppp");
            const response = await fetch( `${api_url}/articledata/${id}`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(upData),
            });    
            if (response.ok) {       
              const updatedData = await response.json();
              Swal.fire({
                title: "Updated!",
                text: "Your file has been updated.",
                icon: "success"
              });
              setData((pre)=> pre.map((items) => 
              items._id === updatedData._id ? {...items,...updatedData} :items
              ));
            } else { 
                 Swal.fire({
                title: "Updated!",
                text: "Your file has been updated.",
                icon: "success"
              });
              console.error(`Failed to update  data. Status: ${response.status}`);
            }}
          } catch (error) {
            Swal.fire({
                title: "Error!",
                text: "An unexpected error occurred.",
                icon: "error"
              });
            console.error('Error updating  data:', error.message);
          }  
    } 
    const renderfield = () => {
        const fields = [];
        for (let i = 0; i < unitinput; i++) {
            let placeholderText = `Enter Part ${i + 1} Like - Shirt,Kurta etc.`;
            if (i === 1) {
                placeholderText = 'Enter Part 2 Like - Pant,Palazzo etc.';
            } else if (i === 2) {
                placeholderText = 'Enter Part 3 Like - Coat,Denim etc.';
            }
            else if (i === 3) {
                placeholderText = 'Enter Part 4 Like - Waistcoat etc.';
            }
            fields.push(
                <Row key={i}>
                    <Col md={6}>
                        <AvField
                            name={`part${i + 1}`}
                            type="text"
                            validate={{ required: { value: true } }}
                            className="form-control"
                            placeholder={placeholderText}

                        />
                    </Col>
                    <Col md={2} className='pt-1'>
                        <p>It is used in:</p>
                    </Col>
                    <Col md={4}>
                        <AvRadioGroup name={`uplopart${i + 1}`} validate={{ required: { value: true } }} errorMessage="Pick one!" className="mt-2">
                            <Row>
                                <Col md={6}>
                                    <AvRadio label="Upper" value="Upper" />
                                </Col>
                                <Col md={6}>
                                    <AvRadio label="Lower" value="Lower" />
                                </Col>
                            </Row>
                        </AvRadioGroup>
                    </Col>
                </Row>
            );
        }
        return fields;
    };
    return (
        <>
            <div className="main-content app-content">
                <Container fluid={true}>
                    <PageHeader title="Article" />
                    <Row>
                        <Col xl={2}></Col>
                        <Col xl={8}>
                            <Card className=" custom-card">
                                <CardHeader className="justify-content-between">
                                    <CardTitle>Article</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <AvForm onValidSubmit={submitForm} ref={refContainer}>
                                        <Row className='accordion_css'>
                                            <Col md={3}>
                                                <p>Who requires it?</p>
                                            </Col>
                                            <Col md={3}>
                                                <AvRadioGroup name="gender" validate={{ required: { value: true } }} errorMessage="Pick one!">
                                                    <Row className='mt-1'>
                                                        <Col md={6}>
                                                            <AvRadio label="Men" value="men" />
                                                        </Col>
                                                        <Col md={6}>
                                                            <AvRadio label="Women" value="women" />
                                                        </Col>
                                                    </Row>
                                                </AvRadioGroup>
                                            </Col>
                                            <Col md={3}>
                                                <p>Article Name:</p>
                                            </Col>
                                            <Col md={3}>
                                                     <AvField
                                                    name="articlename"
                                                    type="text"
                                                    label=""
                                                    onChange={(e) =>{
                                                        const newName = e.target.value;
                                                        setArticlename(newName); 
                                                        const isExist = Data.some(data => data.articlename === newName);                                                       
                                                        if (isExist) {
                                                            Swal.fire({
                                                                title: "Exist!",
                                                                text: "Your Article name ALready Exists.",
                                                                icon: "warning",
                                                              });

                                                              setisSubmitDisabled(true);
                                                        } else {
                                                           // setArticlenameError('');
                                                           setisSubmitDisabled(false);
                                                        }
                                                    }}
                                                    validate={{ required: { value: true } }}
                                                    className="form-control"
                                                    placeholder="Like- Shirt ,Pant,Suit,Kurta etc."
                                                    /> </Col>                                         

                                           
                                            <Col md={3}><p className=''>Is this single unit:</p></Col>
                                            <Col md={3}>
                                                <AvRadioGroup name="unit" validate={{ required: { value: true } }} errorMessage="Pick one!" className="mt-2">
                                                    <Row className='mt-1'>
                                                        <Col md={6}>
                                                            <AvRadio label="Yes" value="yes" onChange={handleSelectChange} checked={selectedValue === 'yes'} />
                                                        </Col>
                                                        <Col md={6}>
                                                            <AvRadio label="No" value="no" onChange={handleSelectChange} checked={selectedValue === 'no'} />
                                                        </Col>
                                                    </Row>
                                                </AvRadioGroup>
                                            </Col>
                                            {(selectedValue === '' || selectedValue === 'yes') &&
                                                <Col md={2} className='pt-1'>
                                                    <p>It is used in:</p>
                                                </Col>
                                            }
                                            {selectedValue === 'no' &&
                                                <Col md={2} className='pt-1'>
                                                    <p>Enter Units:</p>
                                                </Col>
                                            }
                                            <Col md={4} >
                                                {(selectedValue === '' || selectedValue === 'yes') &&
                                                    <AvRadioGroup name="articletype"  validate={{ required: { value: true } }} errorMessage="Pick one!" className="mt-2">
                                                        <Row>
                                                            <Col md={6}>
                                                                <AvRadio label="Upper" value="Upper" />
                                                            </Col>
                                                            <Col md={6}>
                                                                <AvRadio label="Lower" value="Lower" />
                                                            </Col>
                                                        </Row>
                                                    </AvRadioGroup>
                                                }
                                            {/*   {selectedValue === 'yes' &&
                                                   <AvRadioGroup name="articletype"  validate={{ required: { value: true } }} errorMessage="Pick one!" className="mt-2">
                                                   <Row>
                                                       <Col md={6}>
                                                           <AvRadio label="Upper" value="Upper" />
                                                       </Col>
                                                       <Col md={6}>
                                                           <AvRadio label="Lower" value="Lower" />
                                                       </Col>
                                                   </Row>
                                               </AvRadioGroup>
                                                } */}
                                                {selectedValue === 'no' &&
                                                    <AvField
                                                        name="unit"
                                                        type="number"
                                                        validate={{ required: { value: true } }}
                                                        className="form-control"
                                                        placeholder="Enter units?"
                                                        value={unitinput}
                                                        onChange={handleunitchange}
                                                    />
                                                }
                                            </Col>
                                            {selectedValue === 'no' &&
                                                <Col>
                                                    {renderfield()}
                                                </Col>
                                            }

                                        </Row>
                                        <Row>
                                            <Col lg={12}>
                                                <FormGroup className="mb-0 text-center mt-5">
                                                    <div>
                                                        <Button
                                                            type="submit"
                                                            color="primary"
                                                            className="mr-1"
                                                            disabled={isSubmitDisabled}
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                                <div className="card-footer d-none border-top-0">
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                    <Col xl={2}></Col>
                        <Col xl={8}>
                            <Card className=" custom-card">
                                <CardHeader className="justify-content-between">
                                    <CardTitle>Article Data


                                    </CardTitle>
                                    </CardHeader>
                                <CardBody>
                                    {Data ?( 
                                        <div>
                                              <div className="table-responsive">
                                        <table className="table text-nowrap table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Gender</th>     
                                                    <th scope="col">Article Name</th>     
                                                    <th scope="col">Article Unit</th>     
                                                    <th scope="col">Article part Type
                                                    </th>
                                                    <th scope="col">Action</th>                                                   
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Data.map((data,index)=>(
                                                      <tr key={data._id}>
                                                      <td>{index+1}</td>
                                                      <td>{data.gender}</td>
                                                      <td>{data.articlename}</td>
                                                      <td>{data.articleunit}</td>
                                                      <td>
                                                      <Row xs="3"> 
                                                        {data.articleparttype.map((subdata, subindex) => (
                                                            <Col className="table-responsive"  key={subindex}> 
                                                            <table className="table text-nowrap table-bordered secontTAble">
                                                            <thead> <tr ><th >{subdata.articlepart}
                                                                        </th></tr></thead>                
                                                            <tbody> <tr><td>{subdata.articletype}</td>
                                                                    </tr> </tbody>                                                      
                                                            </table>
                                                            </Col>
                                                        ))}</Row>
                                                    </td>
                                                    {/* {data.articleparttype.map((subdata, subindex) => (
                                                                <Col className="table-responsive" key={subindex}>                                                                    
                                                                          <tr>
                                                                            <th scope="col" style={{ width: '150px' }}>{subdata.articletype === 'Upper' && subdata.articlepart}</th>
                                                                            <th scope="col">{subdata.articletype === 'Lower' && subdata.articlepart}</th>
                                                                        </tr>

                                                                       
                                                                </Col>
                                                            ))} */}
                                                      <td>
                                                        <div className="hstack gap-2 flex-wrap">
                                                            <a href="javascript:void(0);" 
                                                            onClick={() => { onOpen(); measurefetchbyid(data._id);}}                                                            
                                                            class="btn btn-icon btn-sm btn-info-transparent rounded-pill"><i
                                                            class="ri-edit-line"></i></a>
                                                            <a href="javascript:void(0);"  
                                                            onClick={() => deletedata(data._id)}  
                                                            class="btn btn-icon btn-sm btn-danger-transparent rounded-pill"><i
                                                                            class="ri-delete-bin-line"></i></a>
                                                        </div>
                                                    </td>
                                                      </tr>
                                                ))}


                                            </tbody>
                                            </table>
                                            </div>




                                        </div>



                                     ):( <div class="spinner-border text-info" role="status">
  <span class="visually-hidden">Loading...</span>
</div>) }

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>




                </Container>
            </div>
            <Modal isOpen={isOpen} size="lg" onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Update Article</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {upData?(
                            <AvForm>
                           <Row className='accordion_css'>
                                <Col md={3}>
                                    <p> Gender:</p>
                                </Col>
                                <Col md={9}>
                                    <AvField
                                        name="name"
                                        type="text"                                        
                                        value={upData.gender}                                       
                                        className="form-control"
                                        readOnly={true}  />
                                </Col>
                                <Col md={3}>
                                    <p>Article name:</p>
                                </Col>
                                <Col md={9}>
                                    <AvField
                                        name="articlename"
                                        type="text"    
                                        readOnly={true}                          
                                        value={upData.articlename}
                                        className="form-control"                                      
                                    />
                                </Col>
                                <Col md={3}>
                                    <p>Article Unit:</p>
                                </Col>
                                <Col md={9}>
                                    <AvField
                                        name="unit"
                                        type="text"
                                        value={upData.articleunit}                                       
                                        validate={{ required: { value: true } }}
                                        className="form-control"
                                        readOnly={true}   
                                        
                                    />
                                </Col>
                                <Col md={6}><p>Article Part</p></Col>
                                <Col md={6}><p>Article type</p></Col>
                                {upData && upData.articleparttype.map((data,index)=>(
                                    <React.Fragment key={index}>
                                        <Col md={6}>
                                            <AvField
                                            name={`articlepart_${data._id}`}
                                                type="text"
                                            
                                                value={data.articlepart}                                      
                                            onChange={(e) => handleMeasureChange(e, data._id)}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <AvRadioGroup 
                                                name={`articletype_${data._id}`}  // Use a unique name for each radio group
                                                value={data.articletype}
                                                validate={{ required: { value: true } }}
                                                className="mt-2"
                                                onChange={(value) => handleRadioChange(data._id, value)} // Handle radio group change
                                            >
                                                <Row>
                                                    <Col md={6}>
                                                        <AvRadio label="Upper" value="Upper" />
                                                    </Col>
                                                    <Col md={6}>
                                                        <AvRadio label="Lower" value="Lower" />
                                                    </Col>
                                                </Row>
                                            </AvRadioGroup>
                                                </Col>
                                            </React.Fragment>  
                                        ))}                                     
                             
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <FormGroup className="mb-0 text-center mt-3 ">
                                        <Button variant='ghost' 
                                         onClick={() => { onClose(); updatedata(upData._id); }}
                                         >Update <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                        </Button>
                                    </FormGroup>
                                </Col>
                                </Row>

                            </AvForm> ):
                            ( <div class="spinner-border text-info" role="status">
                            <span class="visually-hidden">Loading...</span>
                            </div>)}

                    </ModalBody>
                </ModalContent>
            </Modal>



        </>
    )
}

export default ArticalContent
