import React from 'react'
import Aside from '../Components/Aside'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Modal from '../Components/Modal'
import ArticalContent from '../Components/PageComponents/ArticalContent'

const Articals = () => {
    return (
        <>
            <Header />
            <Aside />
            <div className="page">
                <ArticalContent />
                <Modal />
            </div>
            <Footer />
        </>
    )
}

export default Articals
