import React,{useState,useEffect} from 'react'
import { Row, Col, Card, CardHeader, CardBody, FormGroup, Button, CardTitle, Container } from "reactstrap"
import PageHeader from '../PageHeader'
import { AvForm, AvField } from "availity-reactstrap-validation"
import { useNavigate } from 'react-router-dom';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
} from '@chakra-ui/react'
import Swal from 'sweetalert2'
const Customers = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const api_url = process.env.REACT_APP_BASE_URL;
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const [maparticles, setArticlesmap] = useState([]);
    const fetchmap = async () => {
            try {
              const response = await fetch( `${api_url}/addcustomer`);
              if (!response.ok) {
                throw new Error('Failed to fetch articles');
              }
              const data = await response.json();
              setArticlesmap(data);    
            } catch (error) {
              console.log(error.message);
            }
          };
    useEffect(() => {
     
      fetchmap();
    }, []);
    const deletedata= async(id)=>{
        try {
            const result = await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!"
              });          
              if (result.isConfirmed) {
              const response = await fetch( `${api_url}/addcustomer/${id}`, {
                    method: 'DELETE',
                    headers: {
                      'Content-Type': 'application/json',
            },
          });
          console.log(response.status, "PLdelete",response.ok);
          if (response.ok) {
           const updatedData = maparticles.filter((item) => item._id !== id);
           setArticlesmap(updatedData);
           Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success"
          });
          } else {
            Swal.fire({
                title: "Error!",
                text: "Failed to delete the element.",
                icon: "error"
              });
            console.error('Failed to delete article from server');
          }}
        } catch (error) { 
           Swal.fire({
            title: "Error!",
            text: "An unexpected error occurred.",
            icon: "error"
          });
          console.error('Error deleting article:', error.message);
        }
      };
      const filteredArticles = maparticles.filter(a =>
                              a.name.toLowerCase().includes(searchTerm.toLowerCase())|| 
                              a.phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
                              a.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                              a.address1.toLowerCase().includes(searchTerm.toLowerCase()) ||
                              a.reference.toLowerCase().includes(searchTerm.toLowerCase()));
      
      const [Customerdata ,setCustomerData]=useState([]);
      const customerfetchbyid= async(id)=>{
            try {
                const response = await fetch( `${api_url}/addcustomer/${id}`);
                if (response.ok) {
                  const data = await response.json();
                  setCustomerData(data);
                } else {
                  console.error(`Failed to fetch customer data. Status: ${response.status}`);
                }
              } catch (error) {
                console.error('Error fetching customer data:', error.message);
              }}     
    const [updatedData, setUpdatedData] = useState({});
    const handleInputChange = (e) => {
            const { name, value } = e.target;
          setUpdatedData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
        };
const updatedata= async(id)=>{
    try {
        const result = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, update it!"
          });
          if (result.isConfirmed) {
        const response = await fetch( `${api_url}/customername/${id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedData),
        });    
        if (response.ok) {       
          const updatedData = await response.json();
          Swal.fire({
            title: "Updated!",
            text: "Your file has been updated.",
            icon: "success"
          });
          setArticlesmap((pre)=> pre.map((items) => 
          items._id === updatedData._id ? {...items,...updatedData} :items
          ));
        } else { 
             Swal.fire({
            title: "Updated!",
            text: "Your file has been updated.",
            icon: "success"
          });
          console.error(`Failed to update customer data. Status: ${response.status}`);
        }}
      } catch (error) {
        Swal.fire({
            title: "Error!",
            text: "An unexpected error occurred.",
            icon: "error"
          });
        console.error('Error updating customer data:', error.message);
      }  
} 
const [currentPage, setCurrentPage] = useState(1); // Current page number

// Calculate start and end indices for items on the current page
const startIndex = (currentPage - 1) * 10;
const endIndex = currentPage * 10;

// Function to handle navigation to the next page
const nextPage = () => {
  setCurrentPage((prevPage) => prevPage + 1);
};

// Function to handle navigation to the previous page
const prevPage = () => {
  setCurrentPage((prevPage) => prevPage - 1);
};
    return (
        <>
            <div className="main-content app-content" >
                <Container fluid={true}>
                    <PageHeader title="All  Customers" />
                    <Row>
                        <Col xl={12}>
                            <Card className=" custom-card">
                                <CardHeader className="justify-content-between">
                                    <CardTitle> All  Customers
                                      
                                        <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            placeholder="Search Here"
                                            aria-label=".form-control-sm example"
                                        />
                                    </CardTitle>
                                    <div className="btn-list">
                                        <div className="">
                                            <button type="button" onClick={() => navigate('/AddCustomer')} className="btn btn-primary" disabled="">
                                                Add Customers <i className='fa fa-plus-circle'></i></button>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive">
                                        <table className="table text-nowrap table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Phone</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Reference</th>
                                                    <th scope="col">Address Line 1</th>
                                                    <th scope="col">Address Line 2</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {filteredArticles.slice(startIndex, endIndex).map((a,index) => (<tr key={a._id}>
                                                    <th>{index+1}</th>
                                                    <th scope="row">{a.name}</th>
                                                    <td>{a.phone}</td>
                                                    <td>{a.email}</td>
                                                    <td>{a.reference}</td>
                                                    <td>{a.address1}</td>
                                                    <td>{a.address2}</td>
                                                    <td>
                                                        <div className="hstack gap-2 flex-wrap">
                                                            <a  onClick={() => { onOpen(); customerfetchbyid(a._id); }} 
                                                                    class="btn btn-icon btn-sm btn-info-transparent rounded-pill"><i
                                                                        class="ri-edit-line"></i></a>
                                                        
                                                          
                                                            <a onClick={() => deletedata(a._id)}
                                                                        class="btn btn-icon btn-sm btn-danger-transparent rounded-pill"><i
                                                                            class="ri-delete-bin-line"></i></a>
                                                        
                                                        </div>
                                                    </td>
                                                </tr>))}
                                            </tbody>
                                        </table>
                                        <div className="pagination justify-content-end mb-0 mt-4">
                                                <button
                                                className="btn btn-outline-secondary mr-2"
                                                onClick={prevPage}
                                                disabled={currentPage === 1}
                                                >
                                                Previous
                                                </button>
                                                <button
                                                className="btn btn-outline-secondary ml-2"
                                                onClick={nextPage}
                                                disabled={endIndex >= filteredArticles.length}
                                                >
                                                Next
                                                </button>
                                            </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row></Container>
            </div>
            <Modal isOpen={isOpen} size="lg" onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Update Customer Details</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                    {Customerdata ? (    
                          <AvForm>
                           <Row className='accordion_css'>
                                <Col md={3}>
                                    <p> Name:</p>
                                </Col>
                                <Col md={9}>
                                    <AvField
                                        name="name"
                                        type="text"
                                        onChange={handleInputChange}
                                        value={Customerdata.name}
                                        validate={{ required: { value: true } }}
                                        className="form-control"
                                        placeholder="Enter  Name"
                                    />
                                </Col>
                                <Col md={3}>
                                    <p>Phone:</p>
                                </Col>
                                <Col md={9}>
                                    <AvField
                                        name="phone"
                                        type="text"                                      
                                        pattern="[0-9]*"
                                        maxLength="10"
                                        validate={{
                                            required: { value: true, errorMessage: 'Phone number is required' },
                                            maxLength: { value: 10, errorMessage: 'Phone number must be 10 digits' },
                                            pattern: { value: /^[0-9]*$/, errorMessage: 'Please enter a valid phone number' }
                                    
                                        }}
                                        value={Customerdata.phone}
                                        onChange={handleInputChange}
                                        className="form-control"
                                        placeholder="Enter Phone"
                                    />
                                </Col>
                                <Col md={3}>
                                    <p>Email:</p>
                                </Col>
                                <Col md={9}>
                                    <AvField
                                        name="email"
                                        type="text"
                                        value={Customerdata.email}
                                        onChange={handleInputChange}
                                        validate={{ required: { value: true } }}
                                        className="form-control"
                                        placeholder="Enter Email"
                                    />
                                </Col>
                                <Col md={3}>
                                    <p>Reference:</p>
                                </Col>
                                <Col md={9}>
                                    <AvField
                                        name="reference"
                                        type="text"
                                        value={Customerdata.reference}
                                        onChange={handleInputChange}
                                        validate={{ required: { value: true } }}
                                        className="form-control"
                                        placeholder="Enter Reference"
                                    />
                                </Col>
                                <Col md={3}>
                                    <p style={{ fontSize: "13.7px" }}>Address Line 1:</p>
                                </Col>
                                <Col md={9}>
                                    <AvField
                                        className="form-control"
                                        type="text"
                                        value={Customerdata.address1}
                                        onChange={handleInputChange}
                                        placeholder="Enter Address Line 1"
                                        name="address1"
                                        validate={{ required: { value: true } }}
                                        errorMessage="Address cannot be empty"
                                    />
                                </Col>
                                <Col md={3}>
                                    <p style={{ fontSize: "13px" }}>Address Line 2:</p>
                                </Col>
                                <Col md={9}>
                                    <AvField
                                        className="form-control"
                                        type="text"
                                        value={Customerdata.address2}
                                        onChange={handleInputChange}
                                        placeholder="Enter Address Line 2"
                                        name="address2"
                                        validate={{ required: { value: true } }}
                                        errorMessage="Address cannot be empty"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <FormGroup className="mb-0 text-center mt-3 ">
                                        <Button variant='ghost'  onClick={() => { onClose(); updatedata(Customerdata._id); }}>Update <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </AvForm> ):( <p>Loading customer data...</p>)}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default Customers
