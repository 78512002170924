
import React, { useState,useEffect } from 'react'
import PageHeader from '../PageHeader'
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody, FormGroup, Button, CardTitle, Container } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Swal from 'sweetalert2'
// import Swal from 'sweetalert2/dist/sweetalert2.js'
// import 'sweetalert2/src/sweetalert2.scss'

const ElementContent = () => {
    const api_url = process.env.REACT_APP_BASE_URL;
    const [uperelm, setUperelm] = useState();
    const [lowerelm, setLowerelm] = useState();
    const [value, setValue] = useState([]);
    const [lvalue, setLvalue] = useState([]);

    const onchangeUpr = (e) => {
        setUperelm(e.target.value);
    }
    const addUprElement = () => {
        if (uperelm.trim() !== '') {
            setValue([...value, uperelm]);
            setUperelm('');
        }
    }
    const addLowElement = () => {
        if (lowerelm.trim() !== '') {
            setLvalue([...lvalue, lowerelm]);
            setLowerelm('');
        }
    }
    const removeelm = (index) => {
        const updatedItems = [...value];
        updatedItems.splice(index, 1);
        setValue(updatedItems);
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Deleted!",
                    text: " Element has been deleted.",
                    icon: "success"
                });
            }
        });
    };
    const removelowerelm = (index) => {
        const removelw = [...lvalue];
        removelw.splice(index, 1);
        setLvalue(removelw);
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Deleted!",
                    text: " Element has been deleted.",
                    icon: "success"
                });
            }
        });
    }
    const fetchLower = async()=>{
        try {
            const response = await fetch( `${api_url}/element/Lower`);
            const data = await response.json();
            setLvalue(data);       
          } catch (error) {
            console.log(error.message);   
          }
    }
    const fetchUpper = async()=>{
        try {
            const response = await fetch( `${api_url}/element/Upper`);
            const data = await response.json();
            setValue(data);       
          } catch (error) {
            console.log(error.message);   
          }
    }
    useEffect(() => {
        fetchLower();
        fetchUpper();
      }, []);
    const submitForm = async (e, v) => {
                e.preventDefault();
                try {
                    let payload = {
                        Upper: value,
                        Lower:lvalue,
                    }
                    console.log(payload, "PL");
                   // const id="65afb234de272676b932bf54";
                    const id="65c0b10958bf2e22823210ad";
                    const response = await fetch( `${api_url}/element/${id}`, {
                        method: 'PUT',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(payload),
                    });
                    console.log(response.status, "PL");
                    if (response.ok) {
                        Swal.fire({
                            title: "Success!",
                            text: "Element updated succeessfully!",
                            icon: "success"
                        });
                        const responseData = await response.json();
                        console.log('Response Body:', responseData);
                        console.log(response.statusText, ' successfully');
                        // setLvalue([]);
                        // setValue([]);
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                        });
                        console.error('Failed to submit form');
                    }
                } catch (error) {
                    console.error('Error submitting form:', error);
                }   
            }
            
    return (
        <>
            <div className="main-content app-content">
                <Container fluid={true}>
                    <PageHeader title="Elements" />
                    <Row>
                        <Col xl={2}></Col>
                        <Col xl={8}>
                            <Card className=" custom-card">
                                <CardHeader className="justify-content-between">
                                    <CardTitle>Add Elements</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <AvForm onValidSubmit={submitForm}>
                                        <Row className='accordion_css'>
                                            <Col md={6} className='vertical-line'>
                                                <Row>
                                                    <Col md={12}>
                                                        <h5 className='text-muted mb-4'>Upper Clothing Element</h5>
                                                    </Col>
                                                    <Col md={8}>
                                                        <AvField
                                                            name="upper_ele"
                                                            type="text"
                                                            value={uperelm}
                                                            onChange={onchangeUpr}
                                                            label="Upper Element Name"
                                                            className="form-control"
                                                            placeholder="Like- Sleeve,Neck etc"
                                                        />
                                                    </Col>
                                                    <Col md={4}>
                                                        <div className="btn-list mt-4 pt-2">
                                                            <button type="button" onClick={addUprElement} className="btn btn-success btn-wave">Add <i className='fa fa-plus-circle'></i></button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={6}>
                                                <Row>
                                                    <Col md={12}>
                                                        <h5 className='text-muted mb-4'>Lower Clothing Element</h5>
                                                    </Col>
                                                    <Col md={8}>
                                                        <AvField
                                                            name="lower_ele"
                                                            type="text"
                                                            label="Lower Element Name"
                                                            className="form-control"
                                                            placeholder="Like- Length,Waist etc"
                                                            value={lowerelm}
                                                            onChange={(e) => setLowerelm(e.target.value)}
                                                        />
                                                    </Col>
                                                    <Col md={4}>
                                                        <div className="btn-list mt-4 pt-2">
                                                            <button type="button" onClick={addLowElement} className="btn btn-success btn-wave">Add <i className='fa fa-plus-circle'></i></button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>


                                            <Col md={6}>
                                                {value.map((element, index) => (
                                                    <span className="element_name" key={index}>
                                                        {element}
                                                        <Link to={''} onClick={() => removeelm(index)} className="tag-addon">
                                                            <i className="fa fa-times-circle"></i>
                                                        </Link>
                                                    </span>
                                                ))}
                                            </Col>
                                            <Col md={6}>
                                                {lvalue.map((elem, ind) => (
                                                    <span className="element_name" key={ind}>
                                                        {elem}
                                                        <Link to={''} onClick={() => removelowerelm(ind)} className="tag-addon">
                                                            <i className="fa fa-times-circle"></i>
                                                        </Link>
                                                    </span>
                                                ))}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12}>
                                                <FormGroup className="mb-0 text-center mt-5">
                                                    <div>
                                                        <Button
                                                            type="submit"
                                                            color="primary"
                                                            className="mr-1"
                                                        >
                                                            Update
                                                        </Button>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                                <div className="card-footer d-none border-top-0">
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default ElementContent
