import React from 'react'
import Aside from '../Components/Aside'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Modal from '../Components/Modal'
import ElementContent from '../Components/PageComponents/ElementContent'

const Element = () => {
    return (
        <>
            <Header />
            <Aside />
            <div className="page">
                <ElementContent />
            </div>
            <Modal />
            <Footer />
        </>
    )
}

export default Element
